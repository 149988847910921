<template>
  <v-app>
    <Header
      :fShowIconMenu="true"
      @onToggleShowMenuOwner="onToggleShowMenu"
    ></Header>
    <v-navigation-drawer
      v-model="fShowMenu"
      :clipped="$vuetify.breakpoint.lgAndUp"
      color="#639bd6"
      app
    >
      <v-list dense>
        <v-list-item dense>
          <v-list-item-action>
            <v-icon color="white">mdi-account-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ fNameUser }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider color="white"></v-divider>
        <template v-for="fItemMenu in fMainMenuApp">
          <!-- ITEM DO MENU COM SUBITENS -->
          <v-list-group
            v-if="fItemMenu.children"
            :key="fItemMenu.text"
            v-model="fItemMenu.model"
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon small color="white">{{ fItemMenu.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ fItemMenu.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in fItemMenu.children"
              :key="i"
              link
              router
              dense
              @click.native="onClickMenu(child.link)"
            >
              <v-list-item-icon>
                <v-icon small v-if="child.icon">
                  mdi-star
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- ITEM DO MENU SEM SUBITENS -->
          <v-list-item
            v-else
            :key="fItemMenu.text"
            link
            router
            dense
            @click.native="onClickMenu(fItemMenu.link)"
          >
            <v-list-item-action>
              <v-icon small color="white">{{ fItemMenu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                {{ fItemMenu.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <router-view />
        </v-row>
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import { ADMAPP, ADMEMPRESA } from '@/services/consts' //import { _ConsoleLog } from '../services/functions/view'

export default {
  name: 'MainMenu',
  components: {
    Header,
    Footer
  },

  data: () => ({
    fShowMenu: true
    //fMainMenuApp: []
  }),

  computed: {
    ...mapGetters({
      fNameUser: 'NameUser',
      fMainMenuApp: 'MainMenuApp',
      fTypeUser: 'TypeUser'
    })
  },

  mounted() {
    if (this.fTypeUser == ADMAPP || this.fTypeUser == ADMEMPRESA)
      this.$router.push('/mainmenu/dashboard')
  },

  methods: {
    onClickMenu(aLink) {
      this.fShowMenu = false
      if (this.$route.path !== aLink) this.$router.push(aLink)
    },
    onToggleShowMenu() {
      this.fShowMenu = !this.fShowMenu
    }
  }
}
</script>

<style scoped>
.v-list .v-list-item--active .v-icon {
  color: white;
}
.a {
  text-decoration: none;
}
.a:hover {
  text-decoration: underline;
}
</style>

import api from '@/services/api'
//import { _ConsoleLog } from '../../../services/functions/view'

const cPathResource = '/tipousuario'

export default {
  clearTipoUsuarioList(aContext) {
    var lURL = cPathResource
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          if (lResponse.status == 204) {
            aContext.commit('SET_TIPOUSUARIOLIST', [])
          } else {
            aContext.commit('SET_TIPOUSUARIOLIST', lResponse.data)
          }
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_TIPOUSUARIOLIST', [])
          reject(lError)
        })
    })
  },

  clearTipoUsuario(aContext) {
    //aContext.commit('SET_TIPOUSUARIO', aContext.state.fTipoUsuarioClean)
    aContext.commit('CLEAR_TIPOUSUARIO', aContext.state.fTipoUsuarioClean)
  },

  getTipoUsuarioByID(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_tipousuario
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_TIPOUSUARIO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  deleteTipoUsuario(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_tipousuario
    return new Promise((resolve, reject) => {
      api
        .delete(`${lURL}`)
        .then(() => {
          aContext.commit('CLEAR_TIPOUSUARIO')
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  insertTipoUsuario(aContext, aRecord) {
    return new Promise((resolve, reject) => {
      api
        .post(cPathResource, aRecord)
        .then(lResponse => {
          aContext.commit('SET_TIPOUSUARIO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateTipoUsuario(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_tipousuario
    return new Promise((resolve, reject) => {
      api
        .put(`${lURL}`, aRecord)
        .then(lResponse => {
          aContext.commit('SET_TIPOUSUARIO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  /*
  searchTipoUsuarioListByName(aContext, aQueryString) {
    var lURL = cPathResource + 'search' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_TIPOUSUARIOLIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
*/
  rollBackTipoUsuario(aContext) {
    aContext.commit('SET_TIPOUSUARIO', aContext.state.fTipoUsuarioNotChanged)
  }
}

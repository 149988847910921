import api from '@/services/api'

const cPathResource = '/categoriaprofissional'

export default {
  clearCategoriaProfissionalList(aContext) {
    var lURL = cPathResource
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          if (lResponse.status == 204) {
            aContext.commit('SET_CATEGORIAPROFISSIONALLIST', [])
          } else {
            aContext.commit('SET_CATEGORIAPROFISSIONALLIST', lResponse.data)
          }
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_CATEGORIAPROFISSIONALLIST', [])
          reject(lError)
        })
    })
  },

  clearCategoriaProfissional(aContext) {
    aContext.commit(
      'SET_CATEGORIAPROFISSIONAL',
      aContext.state.fCategoriaProfissionalClean
    )
  },

  getCategoriaProfissionalByID(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_categoriaprofissional
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_CATEGORIAPROFISSIONAL', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  deleteCategoriaProfissional(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_categoriaprofissional
    return new Promise((resolve, reject) => {
      api
        .delete(`${lURL}`)
        .then(() => {
          aContext.commit('CLEAR_CATEGORIAPROFISSIONAL')
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  insertCategoriaProfissional(aContext, aRecord) {
    return new Promise((resolve, reject) => {
      api
        .post(cPathResource, aRecord)
        .then(lResponse => {
          aContext.commit('SET_CATEGORIAPROFISSIONAL', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateCategoriaProfissional(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_categoriaprofissional
    return new Promise((resolve, reject) => {
      api
        .put(`${lURL}`, aRecord)
        .then(lResponse => {
          aContext.commit('SET_CATEGORIAPROFISSIONAL', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  /*
  searchCategoriaProfissionalListByName(aContext, aQueryString) {
    var lURL = cPathResource + 'search' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_CATEGORIAPROFISSIONALLIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  */
  rollBackCategoriaProfissional(aContext) {
    aContext.commit(
      'SET_CATEGORIAPROFISSIONAL',
      aContext.state.fCategoriaProfissionalNotChanged
    )
  }
}

//import { _ConsoleLog } from '@/services/functions/view'

export default {
  SET_TIPOUSUARIOLIST: (aState, aValue) => {
    aState.fTipoUsuarioList = [...aValue]
  },

  SET_TIPOUSUARIO: (aState, aValue) => {
    aState.fTipoUsuario = { ...aValue }
    aState.fTipoUsuarioNotChanged = { ...aValue }
  },

  CLEAR_TIPOUSUARIO: aState => {
    aState.fTipoUsuario = { ...aState.fTipoUsuarioClean }
  }
}

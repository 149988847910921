<template>
  <v-menu
    ref="fMenu"
    v-model="fMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="fDtEdit"
        :label="aLabel"
        readonly
        v-bind="attrs"
        v-on="on"
        prepend-icon="mdi-calendar"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="fDataEdit"
      :max="new Date().toISOString().substr(0, 10)"
      @change="SaveDataEdit"
      min="1900-01-01"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerWithCalendar',

  data() {
    return {
      fMenu: false,
      fDataEdit: ''
      //fDtEdit: ''
    }
  },

  props: {
    aLabel: String,
    fDtEdit: String
  },

  mounted() {
    this.fDataEdit = this.aDtEdit
    //this.fDtEdit = this.aDtEdit
  },

  methods: {
    SaveDataEdit(aDate) {
      var lYear = aDate.substring(0, 4)
      var lMonth = aDate.substring(5, 7)
      var lDay = aDate.substring(8)

      this.fDtEdit = `${lDay}/${lMonth}/${lYear}`
      this.$emit(
        'onUpdateDateOwner',
        `${lDay}${lMonth}${lYear}`,
        `${lDay}${lMonth}${lYear}`
      )
    }
  }
}
</script>

import dashboardState from './dashboardState'
import dashboardMutations from './dashboardMutations'
import dashboardGetters from './dashboardGetters'
import dashboardActions from './dashboardActions'

export default {
  state: dashboardState,
  mutations: dashboardMutations,
  getters: dashboardGetters,
  actions: dashboardActions
}

<template>
  <v-card class="elevation-12" width="100%" height="100%">
    <!-- TOOLBAR -->
    <TitleToolBar
      :fTitleForm="fTitleForm"
      :fHaveDialogSearch="fHaveDialogSearch"
      :fShowBtnBack="fShowBtnBack"
      :fShowButtons="fShowButtons"
      :fShowButtonInsert="false"
      :fShowButtonRefresh="false"
      @onNewDataOwner="onNewData"
      @onClearListOwner="onClearList"
      @onToggleShowDialogSearchOwner="onToggleShowDialogSearch"
      @onEventBackOwner="onEventBack"
    />

    <CadastroDialogSearch
      v-if="fShowDialogSearch"
      :fFieldSearch="fFieldsSearch"
      :fShowDialogSearch="fShowDialogSearch"
      fTitleName="Informe o Tipo de Usuário a Pesquisar"
      @onToggleShowDialogSearchOwner="onToggleShowDialogSearch"
      @onGoSearchRecordsOwner="onGoSearchRecords"
    />

    <!-- LIST DATA -->
    <ListData
      :fFilter="fFilter"
      :fHeaders="fHeaders"
      :fListOfRecords="fListOfRecords"
      :fOriginOfRecords="fOriginOfRecords"
      :fLoading="fLoading"
      @onGoDetailRecordOwner="onGoDetailRecord"
    />

    <v-snackbar
      centered
      v-model="fShowSnackBar"
      :timeout="3000"
      :color="fClassSnackBar"
      >{{ fTextSnackBar }}</v-snackbar
    >

    <v-snackbar v-model="fShowMsgGerandoDeclaracao" color="white info--text"
      >Gerando Declaração, aguarde...</v-snackbar
    >

    <DialogConfirm
      v-if="fShowDialogConfirm"
      :fShowDialogConfirm="fShowDialogConfirm"
      :fModeDialogConfirm="fModeDialogConfirm"
      :fTextTitleDialog="fTextTitleDialog"
      :fTextDialog="fTextDialog"
      @onDoDeleteRecordOwner="onDoDeleteRecord"
      @onDoCancelAndBackToListDataOwner="onDoCancelAndBackToListData"
      @onCloseDialogConfirmOwner="onCloseDialogConfirm"
    />

    <!-- FORM DIALOG -->
    <CadastroDialogDataAsprofili
      v-show="fShowDialogData"
      :fShowDialogData="fShowDialogData"
      :fRecord="fRecord"
      :fInEdit="fInEdit"
      :fInInsert="fInInsert"
      :fDataChanged="fPropsDataChanged"
      @onDataChangedOwner="onDataChanged"
      @onSaveRecordOwner="onSaveRecord"
      @onDeleteRecordOwner="onDeleteRecord"
      @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
      @onGoItemCadastroOwner="onGoItemCadastro"
      @onNewDataOwner="onNewData"
      @onCancelRecordChangedOwner="onCancelRecordChanged"
      @onSendLinkEmailAreaAssociadoOwner="onSendLinkEmailAreaAssociado"
      @onGeneratePDFAssociadoOwner="onGeneratePDFAssociado"
      @onGeneratePDFBase64AssociadoOwner="onGeneratePDFBase64Associado"
      @onSendEmailDeclaracaoCancelamentoOwner="
        onSendEmailDeclaracaoCancelamento
      "
      @onReativarAssociadoOwner="onReativarAssociado"
      @onConfirmarCancelamentoAssociadoOwner="onConfirmarCancelamentoAssociado"
    />

    <!--CadastroDialogDataAneBrasil
      v-model="fClienteANEBRASIL"
      :fShowDialogData="fShowDialogData"
      :fRecord="fRecord"
      :fInEdit="fInEdit"
      :fInInsert="fInInsert"
      :fDataChanged="fPropsDataChanged"
      @onDataChangedOwner="onDataChanged"
      @onSaveRecordOwner="onSaveRecord"
      @onDeleteRecordOwner="onDeleteRecord"
      @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
      @onGoItemCadastroOwner="onGoItemCadastro"
      @onNewDataOwner="onNewData"
      @onCancelRecordChangedOwner="onCancelRecordChanged"
      @onSendLinkEmailAreaAssociadoOwner="onSendLinkEmailAreaAssociado"
      @onGeneratePDFAssociadoOwner="onGeneratePDFAssociado"
      @onSendEmailDeclaracaoCancelamentoOwner="
        onSendEmailDeclaracaoCancelamento
      "
      @onReativarAssociadoOwner="onReativarAssociado"
      @onConfirmarCancelamentoAssociadoOwner="onConfirmarCancelamentoAssociado"
    /-->
  </v-card>
</template>

<script>
import { apiCadastroOnline, apiDeclaracao } from '@/services/api'
import CadastroDialogDataAsprofili from './CadastroDialogDataAsprofili.vue'
//import CadastroDialogDataAneBrasil from './CadastroDialogDataAneBrasil.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import TitleToolBar from '@/components/TitleToolBar.vue'
import CadastroDialogSearch from './CadastroDialogSearch.vue'
import ListData from '@/components/ListData.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  _onGoDetailRecord,
  //_onGoSearchRecords,
  _onDoDeleteRecord,
  _onGoSearchRecordsWithParams,
  _InsertRecord,
  _UpdateRecord
} from '@/services/functions/crud'
import {
  _ShowDialogData,
  _ShowErrorMessage,
  _ShowAlertMessage,
  _ShowMessageSnackBar,
  //_doRevalidateToken,
  _ShowDialogConfirm,
  _CloseDialogData,
  _CancelAndBackToListData,
  _onClearList,
  _doMountedView,
  _onNewData,
  _onCancelAndBackToListData,
  _onCloseDialogConfirm,
  _onSaveRecord,
  //_VerifyTokenExpired,
  _Logout,
  _onDoCancelAndBackToListData,
  _onToggleShowDialogSearch,
  _onDataChanged,
  _onDeleteRecord,
  //_OriginOfRecords,
  //_ConsoleLog,
  _onCancelRecordChanged
  //cOriginPanelSearch
} from '@/services/functions/view'
//import { _ConsoleLog } from '../../../services/functions/view'
import {
  cOriginAllRecords,
  //cOriginPanelSearch,
  cFalseStrChar,
  cTrueStrChar,
  ADMAPP,
  ADMEMPRESA,
  cClienteStr,
  cCliente,
  ASPROFILI,
  ANEBRASIL
} from '@/services/consts'

export default {
  name: 'CadastroListData',

  components: {
    DialogConfirm,
    TitleToolBar,
    CadastroDialogSearch,
    ListData,
    CadastroDialogDataAsprofili
    //CadastroDialogDataAneBrasil
  },

  data() {
    return {
      fShowButtons: true,

      fShowDialogSearch: true,
      fHaveDialogSearch: true,

      fTextTitleDialog: String,
      fTextDialog: String,

      fTextSnackBar: '',
      fShowSnackBar: false,

      fInInsert: false,
      fInEdit: false,

      fDataChanged: false,

      fShowDialogConfirm: false,

      fModeDialogConfirm: 0,
      fModeDialogDelete: 1,
      fModeDialogCancelAndBack: 2,

      fShowDialogData: false,
      fClassSnackBar: 'info--text',

      fShowMsgGerandoDeclaracao: false,

      fOriginOfRecords: cOriginAllRecords,

      fEmailLogin: { Email: '' },

      fFilter: '',
      //fFieldsSearch: { name: '' }
      fFieldsSearch: '1',
      fCliente: cCliente
      //fASPROFILI: ASPROFILI,
      //fANEBRASIL: ANEBRASIL,
    }
  },

  computed: {
    ...mapGetters({
      fListOfRecords: 'CadastroList',
      fRecord: 'Cadastro',
      fRecordNotChanged: 'CadastroNotChanged',
      fIdUserLogged: 'IdUserLogged',
      fHeaders: 'CadastroHeaders',
      fLoading: 'CadastroLoading',
      fTypeUser: 'TypeUser'
    }),

    fShowBtnBack: function() {
      return false
    },

    fTitleForm: function() {
      return 'Associados Online'
    },

    fPropsDataChanged: function() {
      return this.fDataChanged
    },

    fClienteASPROFILI: function() {
      return this.fShowDialogData && cCliente == ASPROFILI
    },

    fClienteANEBRASIL: function() {
      return this.fShowDialogData && cCliente == ANEBRASIL
    }
  },

  mounted() {
    _doMountedView(this)
  },

  methods: {
    // -- MAPEAMENTOS DAS ACTIONS DO VUEX --
    ...mapActions({
      clearListOfRecords: 'clearCadastroList',
      clearRecord: 'clearCadastro'
    }),

    // -- EVENTOS --

    onClearList() {
      _onClearList(this)
    },

    onNewData() {
      _onNewData(this)
    },

    onCancelRecordChanged() {
      _onCancelRecordChanged(this, 'rollBackCadastro')
    },

    onCancelAndBackToListData() {
      _onCancelAndBackToListData(this)
    },

    onDoCancelAndBackToListData() {
      _onDoCancelAndBackToListData(this)
    },

    onSaveRecord() {
      _onSaveRecord(this)
    },

    onGoDetailRecord(aRecord) {
      this.fLinkDeclaracao = ''
      _onGoDetailRecord(this, 'getCadastroByID', aRecord)
    },

    onGoSearchRecords(aFieldSearch) {
      //this.fFieldsSearch = aFieldSearch
      //this.fOriginOfRecords = cOriginPanelSearch
      //alert('onGoSearchRecords(aFieldSearch): ' + aFieldSearch)
      _onGoSearchRecordsWithParams(this, 'searchCadastroByParams', aFieldSearch)
    },

    onDataChanged() {
      _onDataChanged(this)
    },

    onDeleteRecord() {
      _onDeleteRecord(this)
    },

    onCloseDialogConfirm() {
      _onCloseDialogConfirm(this)
    },

    onDoDeleteRecord() {
      _onDoDeleteRecord(this, 'deleteCadastro', this.fRecord)
    },

    onToggleShowDialogSearch() {
      _onToggleShowDialogSearch(this)
    },

    onGoItemCadastro() {
      this.$store.dispatch('setIdOpcaoPaiRecordCurrent')
      this.onCancelAndBackToListData()
      this.onClearList()
    },

    onEventBack() {
      this.$store.dispatch('setIdOpcaoPaiBackRecord')
      this.onClearList()
      this.onGoDetailRecord(this.fRecord)
    },

    onSendLinkEmailAreaAssociado() {
      if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
        this.ShowErrorMessage(
          'E-mail não pode ser enviado, Associado solicitou o cancelamento da Associação em ' +
            this.fRecord.dthr_confirmacaocancelamentostr,
          'white error--text'
        )
      } else {
        this.fEmailLogin.Email = this.fRecord.nm_email
        apiCadastroOnline
          .post('/emaillogin', this.fEmailLogin)
          .then(() => {
            this.ShowMessageSnackBar(
              'Verifique sua caixa de email, um email com o link para login acaba de ser enviado',
              'white info--text'
            )
          })
          .catch(lError => {
            this.ShowErrorMessage(
              lError.response.data.MsgErro,
              'white error--text'
            )
          })
      }
    },

    onGeneratePDFAssociado() {
      if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
        this.ShowErrorMessage(
          'Declaração não pode ser gerada, Associado solicitou o cancelamento da Associação em ' +
            this.fRecord.dthr_confirmacaocancelamentostr,
          'white error--text'
        )
      } else {
        apiDeclaracao
          .get(`/declaracao/${this.fRecord.id_cadastro}`)
          .then(lResponse => {
            //this.fLinkDeclaracao = lResponse.data.URLDeclaracao
            window.open(lResponse.data.URLDeclaracao)
          })
          .catch(() => {
            this.ShowErrorMessage(
              'Declaração do Associado não Gerada',
              'white error--text'
            )
          })
          .then(() => {
            this.fEnabledBtnImprimir = true
          })
      }
    },

    onGeneratePDFBase64Associado() {
      if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
        this.ShowErrorMessage(
          'Declaração não pode ser gerada, Associado solicitou o cancelamento da Associação em ' +
            this.fRecord.dthr_confirmacaocancelamentostr,
          'white error--text'
        )
      } else {
        this.fShowMsgGerandoDeclaracao = true
        apiDeclaracao
          .get(`/declaracaoins3/${this.fRecord.id_cadastro}`)
          .then(lResponse => {
            window.open(lResponse.data.URLDeclaracaoS3)
            this.fShowMsgGerandoDeclaracao = false
          })
          .catch(() => {
            this.fShowMsgGerandoDeclaracao = false
            this.ShowErrorMessage(
              'Declaração do Associado não Gerada',
              'white error--text'
            )
          })
          .then(() => {
            this.fEnabledBtnImprimir = true
            this.fShowMsgGerandoDeclaracao = false
          })
      }
    },

    onSendEmailDeclaracaoCancelamento() {
      if (
        this.fRecord.fl_confirmacaocancelamentosys == cFalseStrChar
        /*&&
        this.fRecord.fl_confirmacaocancelamento == cFalseStrChar &&
        this.fRecord.fl_solicitacaocancelamento == cFalseStrChar*/
      ) {
        this.ShowErrorMessage(
          'Declaração de Cancelamento não pode ser gerada, cliente ainda tem o cancelamento confirmado pela Asprofili',
          'white error--text'
        )
      } else {
        apiDeclaracao
          .get(`/declaracaocancelamento/email/${this.fRecord.id_cadastro}`)
          .then(lResponse => {
            this.ShowMessageSnackBar(
              lResponse.data.MsgSuccess,
              'white info--text'
            )
          })
          .catch(() => {
            this.ShowErrorMessage(
              'Declaração do Associado não Gerada',
              'white error--text'
            )
          })
      }
    },

    onReativarAssociado() {
      if (
        this.fRecord.fl_solicitacaocancelamento == cTrueStrChar ||
        this.fRecord.fl_confirmacaocancelamento == cTrueStrChar ||
        this.fRecord.fl_confirmacaocancelamentosys == cTrueStrChar
      ) {
        this.fRecord.fl_solicitacaocancelamento = 'F'
        this.fRecord.fl_confirmacaocancelamento = 'F'
        this.fRecord.fl_confirmacaocancelamentosys = 'F'
        this.onSaveRecord()
      }
    },

    onConfirmarCancelamentoAssociado() {
      if (this.fTypeUser == ADMAPP || this.fTypeUser == ADMEMPRESA) {
        if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
          this.fRecord.fl_confirmacaocancelamentosys = 'T'
          //this.fRecord.dthr_confirmacaocancelamentosys = new Date().toISOString()
          this.onSaveRecord()
        } else {
          this.ShowErrorMessage(
            'Cliente ainda não confirmou o cancelamento atráves do e-mail enviado de confirmação de cancelamento'
          )
        }
      } else {
        this.ShowErrorMessage(
          'Esta função não é liberadao para o seu perfil, solicite ao operacional da ' +
            cClienteStr
        )
      }
    },

    // -- METODOS / FUN��ES --

    Logout() {
      _Logout(this)
    },

    ShowErrorMessage(aErrorMessage) {
      _ShowErrorMessage(this, aErrorMessage)
    },

    ShowAlertMessage(aAlertMessage) {
      _ShowAlertMessage(this, aAlertMessage)
    },

    SetDefaultValues(aRecordParam) {
      aRecordParam.id_usuariosistema = this.fIdUserLogged
      aRecordParam.dthr_reg = new Date().toISOString()
      return aRecordParam
    },

    InsertRecord(aRecord) {
      aRecord = this.SetDefaultValues(aRecord)
      _InsertRecord(this, 'insertCadastro', aRecord)
    },

    UpdateRecord(aRecord) {
      aRecord = this.SetDefaultValues(aRecord)
      _UpdateRecord(this, 'updateCadastro', aRecord)
    },

    ShowDialogConfirm(aTitle, aText, aDialogMode) {
      _ShowDialogConfirm(this, aTitle, aText, aDialogMode)
    },

    ShowDialogData() {
      _ShowDialogData(this)
    },

    CloseDialogData() {
      _CloseDialogData(this)
    },

    CancelAndBackToListData() {
      _CancelAndBackToListData(this)
    },

    ShowMessageSnackBar(aText, aTypeAlert) {
      _ShowMessageSnackBar(this, aText, aTypeAlert)
    }
  }
}

/*
            const lWin = window.open("","_blank")
            let lHtml = ''


            lHtml += '<html>'
            lHtml += '<body style="margin:0!important">'
            //lHtml += '<embed width="100%" height="100%" src="data:application/pdf;base64,'+lResponse.data.DeclaracaoBase64+'" type="application/pdf" />'
            lHtml += '<embed width="100%" height="100%" src="'+lResponse.data.DeclaracaoBase64+'" type="application/pdf" />'
            lHtml += '</body>'
            lHtml += '</html>'

            setTimeout(() => {
              lWin.document.write(lHtml);
            }, 0)

            lHtml += '<html>'
            lHtml += '<body style="margin:0!important">'
            lHtml += '</body>'
            lHtml += '</html>'

            setTimeout(() => {
              lWin.document.write(lHtml)
              let lAhref = lWin.document.createElement("a")
              lAhref.href = lResponse.data.DeclaracaoBase64
              lAhref.download = "declaracao.pdf"
              lAhref.click()
            }, 0)

 let a = document.createElement("a");
 a.href = "data:application/octet-stream;base64,"+data64;
 a.download = "documentName.pdf"
 a.click();
*/
</script>

<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="fShowDialogConfirm"
        persistent
        max-width="400"
        @keydown.esc="onCloseDialogConfirm"
      >
        <v-card>
          <v-card-title class="info white--text">
            {{ fTextTitleDialog }}
          </v-card-title>
          <v-divider />
          <br />
          <v-card-text class="white info--text">
            <v-icon class="blue--text">mdi-alert</v-icon>
            {{ fTextDialog }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              v-if="fModeDialogConfirm == fModeDialogDelete"
              class="info--text"
              @click="onDoDeleteRecord"
              >Sim</v-btn
            >
            <v-btn
              small
              v-if="fModeDialogConfirm == fModeDialogCancelAndBack"
              class="info--text"
              @click="onDoCancelAndBackToListData"
              >Sim</v-btn
            >
            <v-btn small class="info--text" @click="onCloseDialogConfirm"
              >Não</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'DialogConfirm',

  data() {
    return {
      fModeDialogDelete: 1,
      fModeDialogCancelAndBack: 2
    }
  },

  props: {
    fModeDialogConfirm: Number,
    fShowDialogConfirm: Boolean,
    fTextTitleDialog: String,
    fTextDialog: String
  },

  methods: {
    onDoDeleteRecord() {
      this.$emit('onDoDeleteRecordOwner')
    },

    onDoCancelAndBackToListData() {
      this.$emit('onDoCancelAndBackToListDataOwner')
    },

    onCloseDialogConfirm() {
      this.$emit('onCloseDialogConfirmOwner')
    }
  }
}
</script>

<template>
  <v-col :cols="fCols" :sm="fSM" :md="fMD" class="pb-2">
    <v-card>
      <v-row class="no-gutters">
        <div class="col-auto">
          <div class="cyan fill-height">&nbsp;</div>
        </div>
        <div :class="fClassDiv">
          <h5 class="text-truncate text-uppercase">{{ fTitle }}</h5>
          <h1>{{ fTextValue }}</h1>
          <h5>{{ fTextExtraValue }}</h5>
        </div>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'DashBoardCard',

  props: {
    fCols: String,
    fSM: String,
    fMD: String,
    fTitle: String,
    fTextValue: Number,
    fTextExtraValue: String,
    fClassDiv: {
      type: String,
      default: 'col pa-4 py-5 primary--text'
    }
  }
}
</script>

export default {
  fTipoUsuarioList: [],

  fTipoUsuarioHeaders: [
    { text: '', sortable: false, value: 'actions', width: '10px', fixed: true },
    {
      text: 'Descrição Tipo Usuário',
      sortable: true,
      align: 'start',
      value: 'nm_descricao'
    },
    {
      text: 'Ordem',
      sortable: true,
      align: 'start',
      value: 'nr_ordem'
    }
  ],

  fTipoUsuario: {
    id_tipousuario: 0,
    nm_descricao: '',
    nr_ordem: 0,
    dthr_reg: '',
    id_usuariosistema: 0
  },

  fTipoUsuarioNotChanged: {
    id_tipousuario: 0,
    nm_descricao: '',
    nr_ordem: 0,
    dthr_reg: '',
    id_usuariosistema: 0
  },

  fTipoUsuarioClean: {
    id_tipousuario: 0,
    nm_descricao: '',
    nr_ordem: 0,
    dthr_reg: '',
    id_usuariosistema: 0
  }
}

export default {
  MsgErroList: aState => {
    return aState.fMsgErroList
  },

  MsgErroHeaders: aState => {
    return aState.fMsgErroHeaders
  },

  MsgErro: aState => {
    return aState.fMsgErro
  },

  MsgErroNotChanged: aState => {
    return aState.fMsgErroNotChanged
  }
}

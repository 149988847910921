import api from '@/services/api'

const cPathResource = '/msgerro'

export default {
  clearMsgErroList(aContext) {
    var lURL = cPathResource
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          if (lResponse.status == 204) {
            aContext.commit('SET_MSGERROLIST', [])
          } else {
            aContext.commit('SET_MSGERROLIST', lResponse.data)
          }
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_MSGERROLIST', [])
          reject(lError)
        })
    })
  },

  clearMsgErro(aContext) {
    aContext.commit('SET_MSGERRO', aContext.state.fMsgErroClean)
  },

  getMsgErroByID(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_msgerro
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_MSGERRO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  deleteMsgErro(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_msgerro
    return new Promise((resolve, reject) => {
      api
        .delete(`${lURL}`)
        .then(() => {
          aContext.commit('CLEAR_MSGERRO')
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  insertMsgErro(aContext, aRecord) {
    return new Promise((resolve, reject) => {
      api
        .post(cPathResource, aRecord)
        .then(lResponse => {
          aContext.commit('SET_MSGERRO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateMsgErro(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_msgerro
    return new Promise((resolve, reject) => {
      api
        .put(`${lURL}`, aRecord)
        .then(lResponse => {
          aContext.commit('SET_MSGERRO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  /*
  searchMsgErroListByName(aContext, aQueryString) {
    var lURL = cPathResource + 'search' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_MSGERROLIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  */
  rollBackMsgErro(aContext) {
    aContext.commit('SET_MSGERRO', aContext.state.fMsgErroNotChanged)
  }
}

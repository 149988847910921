<template>
  <!-- FORM DIALOG -->
  <v-dialog
    v-model="fShowDialogData"
    max-width="60%"
    class="elevation-1"
    persistent
  >
    <v-card width="100%">
      <v-card-title class="info white--text">
        Menu
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                dense
                v-model="fRecord.nm_texto"
                label="Opção do Menu"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                dense
                v-model="fRecord.nr_ordem"
                label="Ordem"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-checkbox
                dense
                v-model="fRecord.fl_visivelnomenu"
                false-value="F"
                true-value="T"
                class="mx-2"
                label="Visivel no Menu"
                @change="onDataChanged"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="pb-0 mt-n5">
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                dense
                v-model="fRecord.nm_link"
                label="Link"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                dense
                v-model="fRecord.nm_icone"
                label="Icone"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <ButtonsDialogData
        :fInInsert="fInInsert"
        :fInEdit="fInEdit"
        :fShowBtnCustom01="true"
        fIconBtnCustom01="mdi-menu"
        fCaptionBtnCustom01="Itens do Menu"
        :fDataChanged="fDataChanged"
        @onEventBtnCustom01Owner="onGoItemMenu"
        @onSaveRecordOwner="onSaveRecord"
        @onDeleteRecordOwner="onDeleteRecord"
        @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
        @onNewDataOwner="onNewData"
        @onCancelRecordChangedOwner="onCancelRecordChanged"
      ></ButtonsDialogData>
      <!-- /v-card-actions -->
    </v-card>
  </v-dialog>
</template>
<script>
import ButtonsDialogData from '@/components/ButtonsDialogData.vue'

export default {
  name: 'MenuDialogData',

  components: {
    ButtonsDialogData
  },

  props: {
    fShowDialogData: Boolean,
    fRecord: Object,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fDataChanged: Boolean
  },

  methods: {
    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onDataChanged() {
      this.$emit('onDataChangedOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onGoItemMenu() {
      this.$emit('onGoItemMenuOwner')
    }
  }
}
</script>

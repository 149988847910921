<template>
  <!-- FORM DIALOG -->
  <v-dialog
    v-model="fShowDialogData"
    max-width="60%"
    class="elevation-1"
    persistent
  >
    <v-card width="100%">
      <v-card-title class="info white--text">
        Categoria Profissional
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                v-model="fRecord.nm_categoriaprofissional"
                label="Categoria Profissional"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <ButtonsDialogData
        :fInInsert="fInInsert"
        :fInEdit="fInEdit"
        :fShowBtnCustom01="false"
        :fDataChanged="fDataChanged"
        @onEventBtnCustom01Owner="onGoItemCategoriaProfissional"
        @onSaveRecordOwner="onSaveRecord"
        @onDeleteRecordOwner="onDeleteRecord"
        @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
        @onNewDataOwner="onNewData"
        @onCancelRecordChangedOwner="onCancelRecordChanged"
      ></ButtonsDialogData>
      <!-- /v-card-actions -->
    </v-card>
  </v-dialog>
</template>
<script>
import ButtonsDialogData from '@/components/ButtonsDialogData.vue'

export default {
  name: 'CategoriaProfissionalDialogData',

  components: {
    ButtonsDialogData
  },

  props: {
    fShowDialogData: Boolean,
    fRecord: Object,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fDataChanged: Boolean
  },

  methods: {
    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onDataChanged() {
      this.$emit('onDataChangedOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onGoItemCategoriaProfissional() {
      this.$emit('onGoItemCategoriaProfissionalOwner')
    }
  }
}
</script>

export default {
  UsuarioList: aState => {
    return aState.fUsuarioList
  },

  UsuarioHeaders: aState => {
    return aState.fUsuarioHeaders
  },

  Usuario: aState => {
    return aState.fUsuario
  },

  UsuarioNotChanged: aState => {
    return aState.fUsuarioNotChanged
  }
}

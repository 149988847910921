import tipousuarioState from './tipousuarioState'
import tipousuarioMutations from './tipousuarioMutations'
import tipousuarioGetters from './tipousuarioGetters'
import tipousuarioActions from './tipousuarioActions'

export default {
  state: tipousuarioState,
  mutations: tipousuarioMutations,
  getters: tipousuarioGetters,
  actions: tipousuarioActions
}

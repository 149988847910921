<template>
  <v-app>
    <Header :fShowIconMenu="false" />
    <v-main>
      <br />
      <br />
      <br />
      <br />
      <br />
      <v-layout align-center justify-center>
        <v-flex xs10 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar class="white--text" color="#2562A3" dark flat>
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="12" sm="9" md="12">
                    <v-text-field
                      label="Usuário"
                      name="login"
                      v-model="fInfoLogin.user"
                      prepend-icon="mdi-account"
                      type="text"
                      @change="onDataChanged"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="9" md="12">
                    <v-text-field
                      label="Senha"
                      name="password"
                      v-model="fInfoLogin.password"
                      prepend-icon="mdi-lock"
                      type="password"
                      @change="onDataChanged"
                    />
                  </v-col>
                </v-row>
                <div class="red--text" v-if="fMsgErroLogin != ''">
                  <v-icon class="red--text">mdi-alert</v-icon>
                  {{ fMsgErroLogin }}
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn small color="blue lighten-5 info--text" @click="onLogin">
                <v-icon>mdi-login-variant</v-icon>Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <!--/v-container-->
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import { _ConsoleLog } from '@/services/functions/view'

export default {
  name: 'Login',
  components: {
    Header,
    Footer
  },
  data: () => ({
    fInfoLogin: { user: '', password: '' },
    fMsgErroLogin: '',
    fMsgEmpty: '',
    fDataChanged: false
  }),
  mounted() {
    this.$store.dispatch('clearToken')
  },
  methods: {
    onDataChanged() {
      this.fDataChanged = true
      this.fMsgErroLogin = ''
    },
    onLogin() {
      _ConsoleLog('ONLOGIN')
      this.fDataChanged = false
      this.$store
        .dispatch('doLoginApi', this.fInfoLogin)
        .then(lLoggedIn => {
          if (lLoggedIn) {
            _ConsoleLog('ONLOGIN lLoggedIn: ' + lLoggedIn)
            this.$store.dispatch('setMainMenuAppTemp')
            this.$router.push('/mainmenu')
            //this.fMsgErroLogin = 'Acesso Liberado'
          } else {
            this.fMsgErroLogin = 'Usuário e/ou senha inválidos!'
          }
        })
        .catch(() => {
          this.fMsgErroLogin = 'Usuário e/ou senha inválidos!'
          //this.fMsgErroLogin = lError.data.Erro //'Usuário e/ou senha inválidos!'
        })
    }
  }
}
</script>

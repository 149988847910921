import { setToken, decodeToken } from '@/services/api'
import { _ConsoleLog } from '@/services/functions/view'

export default {
  SET_INFODATALOGGED: (aState, aParam) => {
    _ConsoleLog('SET_INFODATALOGGED aParam.Token: ' + aParam.Token)
    setToken(aParam.Token)
    if (aParam.Token == '') {
      aState.userData = { ...aState.userDataClean }
    } else {
      let lPayLoad = decodeToken(aParam.Token)
      aState.userData.nameuser = lPayLoad.nameuser
      aState.userData.iduser = lPayLoad.iduser
      aState.userData.typeuser = lPayLoad.typeuser
    }
    _ConsoleLog(
      'SET_INFODATALOGGED aState.userData.typeuser: ' + aState.userData.typeuser
    )
    aState.token = aParam.Token
  },
  SET_INFOAPIVERSION: (aState, aValue) => {
    aState.apiversion = aValue
  }
}

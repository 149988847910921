export default {
  fCategoriaProfissionalList: [],

  fCategoriaProfissionalHeaders: [
    // objetos JSON com os cmapos que ser�o apresentados
    { text: '', sortable: false, value: 'actions', width: '10px' },
    {
      text: 'Categoria Profissional',
      sortable: true,
      align: 'start',
      value: 'nm_categoriaprofissional'
    }
  ],

  fCategoriaProfissional: {
    id_categoriaprofissional: 0,
    nm_categoriaprofissional: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  },

  fCategoriaProfissionalNotChanged: {
    id_categoriaprofissional: 0,
    nm_categoriaprofissional: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  },

  fCategoriaProfissionalClean: {
    id_categoriaprofissional: 0,
    nm_categoriaprofissional: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  }
}

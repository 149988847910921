export default {
  fDadosDashBoard: {
    fAssociadosHoje: 0,
    fAssociadosOntem: 0,
    fAssociadosMesAtual: 0,
    fAssociadosMesAnterior: 0,
    fAssociadosEfetivosHoje: 0,
    fAssociadosVinculadosHoje: 0,
    fAssociadosEfetivosOntem: 0,
    fAssociadosVinculadosOntem: 0,
    fAssociadosEfetivosMesAtual: 0,
    fAssociadosVinculadosMesAtual: 0,
    fAssociadosEfetivosMesAnterior: 0,
    fAssociadosVinculadosMesAnterior: 0,
    fHoje: '',
    fOntem: '',
    fMesAtual: '',
    fMesAnterior: '',
    fNow: ''
  },
  fDadosDashBoardClean: {
    fAssociadosHoje: 0,
    fAssociadosOntem: 0,
    fAssociadosMesAtual: 0,
    fAssociadosMesAnterior: 0,
    fAssociadosEfetivosHoje: 0,
    fAssociadosVinculadosHoje: 0,
    fAssociadosEfetivosOntem: 0,
    fAssociadosVinculadosOntem: 0,
    fAssociadosEfetivosMesAtual: 0,
    fAssociadosVinculadosMesAtual: 0,
    fAssociadosEfetivosMesAnterior: 0,
    fAssociadosVinculadosMesAnterior: 0,
    fHoje: '',
    fOntem: '',
    fMesAtual: '',
    fMesAnterior: '',
    fNow: ''
  },
  /*
  CORES MATHEUS
  fSeriesPieBackGroundColorsUF: [
    '#2F4F4F',
    '#0000FF',
    '#EEE9E9',
    '#8B1A1A',
    '#FF8C69',
    '#EE9A00',
    '#000000',
    '#8B2500',
    '#FF0000',
    '#FF1493',
    '#EEC900',
    '#8B658B',
    '#006400',
    '#228B22',
    '#FFFF00',
    '#FFB5C5',
    '#FF00FF',
    '#7A378B',
    '#FFE1FF',
    '#1C1C1C',
    '#6959CD',
    '#00FF7F',
    '#146525',
    '#481465',
    '#65144c',
    '#576514'
  ],
  */
  fSeriesPieBackGroundColorsUF: [
    '#574A9C',
    '#22BBCD',
    '#6DA951',
    '#FF8C73',
    '#4C6CB5',
    '#D1D445',
    '#CF3750',
    '#FFC353',
    '#FDA63C',
    '#FC7033',
    '#EF4128',
    '#8D54A1',
    '#574A9C',
    '#22BBCD',
    '#6DA951',
    '#FF8C73',
    '#4C6CB5',
    '#D1D445',
    '#CF3750',
    '#FFC353',
    '#FDA63C',
    '#FC7033',
    '#EF4128',
    '#8D54A1',
    '#574A9C',
    '#22BBCD'
  ],
  fLabelsLine: [],
  fSeriesLineAssociados: [],
  fSeriesLineAssociadosEfetivos: [],
  fSeriesLineAssociadosVinculados: [],
  fTitleChartLine: '',

  fLabelsPieUF: [],
  fSeriesPieAssociadosPorUF: [],
  fSeriesPieAssociadosEfetivosPorUF: [],
  fSeriesPieAssociadosVinculadosPorUF: [],
  fTitleChartPiePorUF: '',

  fLabelsBarUF: [],
  fSeriesBarAssociadosPorUF: [],
  fSeriesBarAssociadosEfetivosPorUF: [],
  fSeriesBarAssociadosVinculadosPorUF: [],
  fTitleChartBarPorUF: ''
}

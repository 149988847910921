import categoriaprofissionalState from './categoriaprofissionalState'
import categoriaprofissionalMutations from './categoriaprofissionalMutations'
import categoriaprofissionalGetters from './categoriaprofissionalGetters'
import categoriaprofissionalActions from './categoriaprofissionalActions'

export default {
  state: categoriaprofissionalState,
  mutations: categoriaprofissionalMutations,
  getters: categoriaprofissionalGetters,
  actions: categoriaprofissionalActions
}

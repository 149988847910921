import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'
//import zingchartVue from 'zingchart-vue'

//Vue.component('zingchart', zingchartVue)
Vue.config.productionTip = false
Vue.directive('mask', VueMaskDirective)
var vm = new Vue({
  router,
  store,
  vuetify,
  //zingchartVue,
  render: h => h(App)
}).$mount('#app')

export default vm

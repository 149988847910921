<template>
  <!-- FORM DIALOG -->
  <v-dialog
    v-model="fShowDialogData"
    max-width="80%"
    max-height="80%"
    class="elevation-1"
    persistent
    scrollable
  >
    <v-card width="100%">
      <v-card-title class="info white--text">
        Associado Online
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form>
          <v-row class="pb-0 mb-n2">
            <br />
            <v-col col="9" sm="2" md="2">
              <v-select
                dense
                v-model="fRecord.cd_tipoassociacao"
                :items="fTipoAssociacao"
                menu-props="auto"
                label="Categoria Associativa"
                hide-details
                item-value="value"
                item-text="text"
                @change="onDataChanged"
                @keydown="onDataChanged"
              ></v-select>
            </v-col>
            <v-col col="9" sm="7" md="7">
              <v-text-field
                dense
                label="Status"
                v-model="fStatusAssociado"
                type="text"
                color="blue"
                readonly
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <!--v-col col="9" sm="2" md="2">
              <v-btn v-if="fLinkDeclaracao==''" class="white info--text" small v-bind="attrs" @click="onGeneratePDFBase64AssociadoLocal">
                Gerar Declaração
              </v-btn>
              <v-btn v-if="fLinkDeclaracao!=''" class="white info--text" small v-bind="attrs" target="_self" :href="fLinkDeclaracao">
                Download Declaração
              </v-btn>
            </v-col-->
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                dense
                label="Nome"
                v-model="fRecord.nm_nome"
                type="text"
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="2" md="2">
              <v-text-field
                dense
                label="CPF"
                v-model="fRecord.nr_cpf"
                type="text"
                maxlength="11"
              />
            </v-col>

            <v-col cols="9" sm="3" md="3">
              <v-text-field
                v-mask="mask"
                dense
                v-model="fRecord.dt_nascimento"
                label="Data de Nascimento"
                prepend-icon="mdi-calendar"
                :rules="[rules.datanascimento]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="5" md="5">
              <v-text-field
                dense
                label="E-mail Principal"
                v-model="fRecord.nm_email"
                type="text"
                maxlength="100"
                onpaste="return false"
                ondrop="return false"
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>

            <v-col cols="9" sm="5" md="5">
              <v-text-field
                dense
                label="E-mail Secundário"
                v-model="fRecord.nm_email2"
                type="text"
                maxlength="100"
                onpaste="return false"
                ondrop="return false"
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                dense
                label="Telefone Celular"
                v-model="fRecord.nm_telefonecelular"
                type="text"
                maxlength="30"
              />
            </v-col>

            <v-col cols="9" sm="3" md="3">
              <v-text-field
                dense
                label="Telefone Comercial"
                v-model="fRecord.nm_telefonecomercial"
                type="text"
                maxlength="30"
              />
            </v-col>

            <v-col cols="9" sm="3" md="3">
              <v-text-field
                dense
                label="Telefone Residencial"
                v-model="fRecord.nm_telefoneresidencial"
                type="text"
                maxlength="30"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                dense
                label="Nome da Mãe"
                v-model="fRecord.nm_mae"
                type="text"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                dense
                label="Endereço"
                v-model="fRecord.nm_endereco"
                type="text"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="2" md="2">
              <v-text-field
                dense
                label="Numero"
                v-model="fRecord.nr_numero"
                type="text"
              />
            </v-col>

            <v-col cols="9" sm="4" md="4">
              <v-text-field
                dense
                label="Complemento"
                v-model="fRecord.nm_complemento"
                type="text"
              />
            </v-col>

            <v-col cols="9" sm="4" md="4">
              <v-text-field
                dense
                label="Bairro"
                v-model="fRecord.nm_bairro"
                type="text"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="6" md="6">
              <v-text-field
                dense
                label="Cidade"
                v-model="fRecord.nm_cidade"
                type="text"
              />
            </v-col>

            <v-col col="9" sm="2" md="2">
              <v-select
                dense
                v-model="fRecord.cd_uf"
                :items="fUFs"
                menu-props="auto"
                label="UF"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="9" sm="2" md="2">
              <v-text-field
                dense
                label="CEP"
                v-model="fRecord.nr_cep"
                type="text"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="fRecord.cd_tipoassociacao === 2">
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                dense
                label="Associado Vinculado"
                v-model="fRecord.nm_nomeassociadovinculado"
                type="text"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="9" sm="6" md="6">
              <v-select
                dense
                :label="fLabelCategoriaProfissional"
                v-model="fRecord.nm_profissao"
                :items="fCategoriasProfissionais"
                menu-props="auto"
                hide-details
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <v-col
              cols="9"
              sm="4"
              md="4"
              v-if="fRecord.cd_tipoassociacao === 2"
            >
              <v-select
                dense
                label="Parentesco"
                v-model="fRecord.cd_parentesco"
                :items="fParentesco"
                menu-props="auto"
                hide-details
                item-value="value"
                item-text="text"
                v-if="fRecord.cd_tipoassociacao === 2"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <ButtonsDialogData
        :fInInsert="fInInsert"
        :fInEdit="fInEdit"
        :fDataChanged="fDataChanged"
        :fShowButtonNewData="false"
        :fShowButtonDelete="false"
        :fShowBtnCustom01="false"
        :fCaptionBtnCustom01="fCaptionBtnCustom01"
        :fIconBtnCustom01="fIconBtnCustom01"
        :fShowMenu="true"
        :fMenuItems="fMenuItems"
        @onClickMenuOwner="onClickMenu"
        @onEventBtnCustom01Owner="onExecuteBtnCustom"
        @onSaveRecordOwner="onSaveRecord"
        @onDeleteRecordOwner="onDeleteRecord"
        @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
        @onNewDataOwner="onNewData"
        @onCancelRecordChangedOwner="onCancelRecordChanged"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmReenvioEmail"
        :fShowDialogConfirmSimple="fShowDialogConfirmReenvioEmail"
        fTextTitleDialog="Reenvio E-mail Acesso Area Associado "
        fTextDialog="Confirma Reenvio E-mail Acesso Area Associado?"
        @onResultYesOwner="onSendLinkEmailAreaAssociado"
        @onResultNoOwner="onResultNoSendLinkEmailAreaAssociado"
        fMaxWidth="450"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmGeneratePDFAssociado"
        :fShowDialogConfirmSimple="fShowDialogConfirmGeneratePDFAssociado"
        fTextTitleDialog="Gerar Declaração do Associado"
        fTextDialog="Confirma Geração Declaração do Associado?"
        @onResultYesOwner="onGeneratePDFAssociado"
        @onResultNoOwner="onResultNoGeneratePDFAssociado"
        fMaxWidth="450"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmGeneratePDFBase64Associado"
        :fShowDialogConfirmSimple="fShowDialogConfirmGeneratePDFBase64Associado"
        fTextTitleDialog="Gerar Declaração do Associado"
        fTextDialog="Confirma Geração Declaração do Associado?"
        @onResultYesOwner="onGeneratePDFBase64Associado"
        @onResultNoOwner="onResultNoGeneratePDFBase64Associado"
        fMaxWidth="450"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmReativarAssociado"
        :fShowDialogConfirmSimple="fShowDialogConfirmReativarAssociado"
        fTextTitleDialog="Reativar Associado"
        fTextDialog="Confirma Reativação do Associado?"
        @onResultYesOwner="onReativarAssociado"
        @onResultNoOwner="onResultNoReativarAssociado"
        fMaxWidth="450"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmCancelamentoAssociado"
        :fShowDialogConfirmSimple="fShowDialogConfirmCancelamentoAssociado"
        fTextTitleDialog="Confirmar Cancelamento do Associado"
        fTextDialog="Confirma Cancelamento do Associado?"
        @onResultYesOwner="onConfirmarCancelamentoAssociado"
        @onResultNoOwner="onResultNoCancelamentoAssociado"
        fMaxWidth="450"
      />

      <v-snackbar v-model="fShowMsgGerandoDeclaracao" color="white info--text"
        >Gerando Declaração, aguarde...</v-snackbar
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { apiExt, apiDeclaracao } from '@/services/api'
import ButtonsDialogData from '@/components/ButtonsDialogData.vue'
import DialogConfirmSimple from '@/components/DialogConfirmSimple.vue'
import {
  cTrueStrChar,
  cFalseStrChar,
  cClienteStr,
  ADMAPP
} from '@/services/consts'
import { _ConsoleLog } from '../../../services/functions/view'
import { mapGetters } from 'vuex'

export default {
  name: 'CadastroDialogDataAsprofili',

  components: {
    ButtonsDialogData,
    DialogConfirmSimple
  },

  props: {
    fShowDialogData: Boolean,
    fRecord: Object,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fDataChanged: Boolean
  },

  data() {
    return {
      fLinkDeclaracao: '',
      mask: '##/##/####',
      fTypeUserADMAPP: ADMAPP,
      fShowDialogConfirmReenvioEmail: false,
      fShowDialogConfirmGeneratePDFAssociado: false,
      fShowDialogConfirmGeneratePDFBase64Associado: false,
      fShowDialogConfirmReativarAssociado: false,
      fShowDialogConfirmCancelamentoAssociado: false,
      fShowMsgGerandoDeclaracao: false,
      //fMenuItems: [],
      fMenuItemsAssociadoAtivo: [
        {
          Title: 'Reenviar E-mail Acesso Area Associado',
          Action: 'Send-Email',
          Icon: 'mdi-send'
        },
        {
          Title: 'Gerar Declaração do Associado',
          Action: 'Generate-Declaraction',
          Icon: 'mdi-send'
        },
        {
          Title: 'Gerar Declaração do Associado - Alternativa',
          Action: 'Generate-DeclaractionBase64',
          Icon: 'mdi-send'
        }
      ],
      fMenuItemsAssociadoCancelado: [
        {
          Title: 'Confirmar Cancelamento do Associado',
          Action: 'Confirmar-Cancelamento-Associado',
          Icon: 'mdi-send'
        },
        {
          Title: 'Reativar Associado',
          Action: 'Reativar-Associado',
          Icon: 'mdi-send'
        },
        {
          Title: 'Enviar E-mail com Declaração de Associado Cancelado',
          Action: 'Send-Email-Declaracao-Cancelamento',
          Icon: 'mdi-send'
        }
      ],
      rules: {
        email: value => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (re.test(value)) {
            return true
          } else {
            return 'E-mail inválido'
          }
        },
        datanascimento: AValue => {
          AValue = AValue.split('/')
          var LAno = parseInt(AValue[2])
          var LMes = parseInt(AValue[1])
          var LDia = parseInt(AValue[0])
          var MyData = new Date(LAno, LMes - 1, LDia)

          var LAnoAtual = new Date().getFullYear()

          var LIdade = LAnoAtual - LAno
          if (
            MyData.getMonth() + 1 != LMes ||
            MyData.getDate() != LDia ||
            MyData.getFullYear() != LAno
          ) {
            return 'A data informada é inválida'
          } else {
            if (LIdade < 18) {
              return 'Idade deve ser maior ou igual a 18 anos'
            } else {
              return true
            }
          }
        }
      },

      fCaptionBtnCustom01: 'Reenviar E-mail Acesso Area Associado',

      fMenu: false,

      fEmailLogin: { Email: '' },

      fTipoAssociacao: [
        { text: 'Sócio Efetivo', value: 1 },
        { text: 'Sócio Vinculado', value: 2 }
      ],

      fParentesco: [
        { text: 'MARIDO', value: 1 },
        { text: 'ESPOSA', value: 2 },
        { text: 'FILHO', value: 3 },
        { text: 'FILHA', value: 4 },
        { text: 'PAI', value: 5 },
        { text: 'MÃE', value: 6 },
        { text: 'NETO', value: 7 },
        { text: 'NETA', value: 8 },
        { text: 'GENRO', value: 9 },
        { text: 'NORA', value: 10 },
        { text: 'COMPANHEIRO', value: 14 },
        { text: 'COMPANHEIRA', value: 15 },
        { text: 'IRMÃO', value: 16 },
        { text: 'IRMÃ', value: 17 },
        { text: 'ENTEADO', value: 21 },
        { text: 'ENTEADA', value: 22 }
      ],

      fUFs: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MG',
        'MS',
        'MT',
        'PA',
        'PB',
        'PE',
        'PI',
        'PR',
        'RJ',
        'RN',
        'RO',
        'RR',
        'RS',
        'SC',
        'SE',
        'SP',
        'TO'
      ],

      fCategoriasProfissionais: [
        'Administradores',
        'Arquitetos ',
        'Assistentes Sociais ',
        'Atuários ',
        'Arquivologistas',
        'Automação Industrial ',
        'Bacharéis em Direito ',
        'Biblioteconomia ',
        'Biomédicos ',
        'Biólogos',
        'Ciências Aeronáuticas ',
        'Cinema ',
        'Comércio Exterior ',
        'Contabilista ',
        'Dentista ',
        'Desenho Industrial ',
        'Designers de Interiores ',
        'Designers de Moda ',
        'Designers Gráficos ',
        'Economistas',
        'Educação Física ',
        'Enfermeiros ',
        'Engenheiros',
        'Estatisticos',
        'Farmacêuticos',
        'Filósofos',
        'Físicos',
        'Fisioterapeutas',
        'Fonoaudiólogos',
        'Fotógrafos',
        'Gastronomia',
        'Geografia',
        'Geologia',
        'Gestão Ambiental',
        'Gestão Comercial',
        'Gestão de Tecnologia da Informação',
        'Gestão de Recursos Humanos',
        'Gestão de Segurança Privada',
        'Gestão de Seguros',
        'Gestão de Turismo',
        'Gestão Financeira',
        'Gestão Hospitalar',
        'Gestão Pública',
        'Hotelaria',
        'Historiadores',
        'Jornalistas',
        'Letras',
        'Logísticas',
        'Matemáticos',
        'Médicos',
        'Negócios Imobiliários',
        'Nutricionistas',
        'Pedagogos',
        'Professores de ensino médio e curso superior',
        'Profissionais de Informática',
        'Psicólogos',
        'Publicitários',
        'Químicos',
        'Radiologistas',
        'Redes de Telecomunicações',
        'Relações Internacionais',
        'Secretariados',
        'Segurança do Trabalho',
        'Sociólogos',
        'Turismo',
        'Técnicos em Contabilidade',
        'Técnicos em Enfermagem',
        'Técnicos em Laboratórios',
        'Teólogos',
        'Veterinários'
      ]
    }
  },

  mounted() {
    _ConsoleLog('TypeUser: ' + this.fTypeUser)
    _ConsoleLog('TypeUser: ' + this.fTypeUserADMAPP)
    this.fLinkDeclaracao = ''
    apiExt
      .get('/profissao')
      .then(lResponse => {
        this.fCategoriasProfissionais = [...lResponse.data]
      })
      .catch(() => {
        //alert()
      })
  },

  computed: {
    ...mapGetters({
      fTypeUser: 'TypeUser'
    }),
    /*fAssociadoAtivo: () => {
      return this._AssociadoAtivo()
    },*/
    fMenuItems: function() {
      if (
        this.fRecord.fl_confirmacaocancelamentosys == cFalseStrChar &&
        this.fRecord.fl_confirmacaocancelamento == cFalseStrChar &&
        this.fRecord.fl_solicitacaocancelamento == cFalseStrChar
      ) {
        return this.fMenuItemsAssociadoAtivo
      } else {
        return this.fMenuItemsAssociadoCancelado
      }
    },
    fIconBtnCustom01: () => {
      return 'mdi-menu'
    },
    fStatusAssociado: function() {
      if (this.fRecord.fl_confirmacaocancelamentosys == cTrueStrChar)
        return (
          'Cancelamento Confirmado pela ' +
          cClienteStr +
          ' em ' +
          this.fRecord.dthr_confirmacaocancelamentosysstr
        )
      else if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar)
        return (
          'Cancelamento Confirmado pelo Cliente em ' +
          this.fRecord.dthr_confirmacaocancelamentostr
        )
      else if (this.fRecord.fl_solicitacaocancelamento == cTrueStrChar)
        return (
          'Cancelamento Solicitado pelo Cliente em ' +
          this.fRecord.dthr_solicitacaocancelamentostr
        )
      else return 'Associado em ' + this.fRecord.dthr_insertstr
    },
    fLabelCategoriaProfissional: function() {
      if (this.fRecord.cd_tipoassociacao == 1) {
        return 'Categoria Profissional'
      } else {
        return 'Categoria Profissional do Associado Vinculado'
      }
    }
  },

  methods: {
    /*_AssociadoAtivo() {
      return (
        this.fRecord.fl_confirmacaocancelamentosys == cFalseStrChar &&
        this.fRecord.fl_confirmacaocancelamento == cFalseStrChar &&
        this.fRecord.fl_solicitacaocancelamento == cFalseStrChar
      )
    },*/

    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onDataChanged() {
      this.$emit('onDataChangedOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onGoItemCadastro() {
      this.$emit('onGoItemCadastroOwner')
    },

    onExecuteBtnCustom() {
      this.onSendLinkEmailAreaAssociado()
    },

    onSendLinkEmailAreaAssociado() {
      this.$emit('onSendLinkEmailAreaAssociadoOwner')
      this.fShowDialogConfirmReenvioEmail = false
    },

    onResultNoSendLinkEmailAreaAssociado() {
      this.fShowDialogConfirmReenvioEmail = false
    },

    onGeneratePDFAssociado() {
      this.$emit('onGeneratePDFAssociadoOwner')
      this.fShowDialogConfirmGeneratePDFAssociado = false
    },

    onGeneratePDFBase64Associado() {
      this.$emit('onGeneratePDFBase64AssociadoOwner')
      this.fShowDialogConfirmGeneratePDFBase64Associado = false
    },

    onResultNoGeneratePDFAssociado() {
      this.fShowDialogConfirmGeneratePDFAssociado = false
    },

    onResultNoGeneratePDFBase64Associado() {
      this.fShowDialogConfirmGeneratePDFBase64Associado = false
    },

    onResultNoReativarAssociado() {
      this.fShowDialogConfirmReativarAssociado = false
    },

    onSendEmailDeclaracaoCancelamento() {
      this.$emit('onSendEmailDeclaracaoCancelamentoOwner')
    },

    onReativarAssociado() {
      this.$emit('onReativarAssociadoOwner')
      this.fShowDialogConfirmReativarAssociado = false
    },

    onResultNoCancelamentoAssociado() {
      this.fShowDialogConfirmCancelamentoAssociado = false
    },

    onConfirmarCancelamentoAssociado() {
      this.$emit('onConfirmarCancelamentoAssociadoOwner')
      this.fShowDialogConfirmCancelamentoAssociado = false
    },

    onGeneratePDFBase64AssociadoLocal() {
      if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
        this.ShowErrorMessage(
          'Declaração não pode ser gerada, Associado solicitou o cancelamento da Associação em ' +
            this.fRecord.dthr_confirmacaocancelamentostr,
          'white error--text'
        )
      } else {
        this.fShowMsgGerandoDeclaracao = true
        apiDeclaracao
          .get(`/declaracaoins3/${this.fRecord.id_cadastro}`)
          .then(lResponse => {
            this.fLinkDeclaracao = lResponse.data.URLDeclaracaoS3
            //window.open(lResponse.data.URLDeclaracaoS3)
            this.fShowMsgGerandoDeclaracao = false
          })
          .catch(() => {
            this.fShowMsgGerandoDeclaracao = false
            this.ShowErrorMessage(
              'Declaração do Associado não Gerada',
              'white error--text'
            )
          })
          .then(() => {
            this.fEnabledBtnImprimir = true
            this.fShowMsgGerandoDeclaracao = false
          })
      }
    },

    onClickMenu(aAction) {
      switch (aAction) {
        case 'Send-Email':
          this.fShowDialogConfirmReenvioEmail = true
          break
        case 'Generate-Declaraction':
          this.fShowDialogConfirmGeneratePDFAssociado = true
          break
        case 'Generate-DeclaractionBase64':
          this.fShowDialogConfirmGeneratePDFBase64Associado = true
          break
        case 'Send-Email-Declaracao-Cancelamento':
          this.onSendEmailDeclaracaoCancelamento()
          break
        case 'Reativar-Associado':
          this.fShowDialogConfirmReativarAssociado = true
          //this.onReativarAssociado()
          break
        case 'Confirmar-Cancelamento-Associado':
          this.fShowDialogConfirmCancelamentoAssociado = true
          //this.onConfirmarCancelamentoAssociado()
          break
      }
    }
  }
}
</script>

<style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>

<template>
  <!-- -->
  <v-row justify="center">
    <v-dialog
      v-model="fShowDialogSearch"
      max-width="80%"
      class="elevation-1"
      persistent
    >
      <v-card-actions id="frmSearch">
        <v-card width="100%">
          <v-card-title class="info white--text">
            Pesquisar
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-form>
              <v-row dense>
                <v-col cols="12" sm="10" md="10">
                  <v-text-field
                    dense
                    label="Nome"
                    append-icon="mdi-magnify"
                    type="text"
                    v-model="fNome"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    dense
                    label="CPF"
                    append-icon="mdi-magnify"
                    type="text"
                    v-model="fCPF"
                    maxlength="11"
                  />
                </v-col>
                <v-col cols="12" sm="7" md="7">
                  <v-text-field
                    dense
                    label="Email"
                    append-icon="mdi-magnify"
                    type="text"
                    v-model="fEmail"
                  />
                </v-col>
              </v-row>
              <!--v-row>
                <v-col col="6" sm="6" md="6">
                  <v-select
                    v-model="fFieldSearch"
                    :items="fFiltro"
                    menu-props="auto"
                    label="Status"
                    hide-details
                    item-value="value"
                    item-text="text"
                  ></v-select>
                </v-col>
              </v-row-->
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions class="info white--text">
            <v-spacer />
            <v-btn color="white info--text" small @click="onGoSearchRecords">
              <v-icon>mdi-magnify</v-icon>Pesquisar
            </v-btn>
            <v-btn
              color="white info--text"
              small
              @click="onToggleShowDialogSearch"
            >
              <v-icon>mdi-backup-restore</v-icon>Voltar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-actions>
      <!-- -->
    </v-dialog>
  </v-row>
</template>
<script>
import { _ConsoleLog } from '../../../services/functions/view'
export default {
  name: 'DialogSearch',

  props: {
    fFieldSearch: {
      type: String,
      default: '1'
    },
    fTitleName: String,
    fShowDialogSearch: Boolean
  },

  data() {
    return {
      /*fFiltro: [
        { text: 'ASSOCIADO', value: 1 },
        { text: 'CANCELAMENTO SOLICITADO', value: 2 },
        { text: 'CANCELAMENTO CONFIRMADO PELO CLIENTE', value: 3 },
        { text: 'CANCELAMENTO CONFIRMADO PELA ASSOCIAÇÃO', value: 4 }
      ]*/
      fNome: '',
      fCPF: '',
      fEmail: '' 
    }
  },

  methods: {
    onGoSearchRecords() {
      var lParams = { fNome:'', fCPF:'', fEmail:'' }
      lParams.fNome = this.fNome
      lParams.fCPF = this.fCPF
      lParams.fEmail = this.fEmail
      _ConsoleLog(JSON.stringify(lParams))
      this.$emit('onGoSearchRecordsOwner', lParams)
    },

    onToggleShowDialogSearch() {
      this.$emit('onToggleShowDialogSearchOwner')
    }
  }
}
</script>

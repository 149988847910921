<template>
  <div>
    <v-row>
      <v-spacer />
      <div class="divTitle">{{ fTitleChartPiePorUF }}</div>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col cols="4">
        <zingchart :data="fChartDataTotalAssociadosPorUF"></zingchart>
      </v-col>
      <v-col cols="4">
        <zingchart :data="fChartDataAssociadosEfetivosPorUF"></zingchart>
      </v-col>
      <v-col cols="4">
        <zingchart :data="fChartDataAssociadosVinculadosPorUF"></zingchart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import { _ConsoleLog } from '@/services/functions/view'
import 'zingchart/es6'
import zingchartVue from 'zingchart-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChartPieAssociadosPorUF',

  components: {
    zingchart: zingchartVue
  },

  computed: {
    ...mapGetters({
      fLabelsPieUF: 'LabelsPieUF',
      fSeriesPieAssociadosPorUF: 'SeriesPieAssociadosPorUF',
      fSeriesPieAssociadosEfetivosPorUF: 'SeriesPieAssociadosEfetivosPorUF',
      fSeriesPieAssociadosVinculadosPorUF: 'SeriesPieAssociadosVinculadosPorUF',
      fTitleChartPiePorUF: 'TitleChartPiePorUF'
    }),
    fChartDataTotalAssociadosPorUF() {
      return {
        type: 'pie',
        globals: {
          fontSize: 14
        },
        title: {
          text: 'Total de Associados',
          'font-size': '25px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
          // will force margin and padding on title
          // adjustLayout: true
        },
        legend: {
          draggable: true,
          // will force margin and padding on legen d
          adjustLayout: true
        },
        scaleR: {},
        // plot represents general series, or plots, styling
        plot: {
          // hoverstate
          tooltip: {
            // turn individual point tooltip off
            // visible: false,
            padding: '10 15',
            borderRadius: 3,
            // % symbol represents a token to insert a value. Full list here:
            // https://www.zingchart.com/docs/tutorials/chart-elements/zingchart-tokens/
            text: '%plot-text %kl teve %v associados',
            // htmlMode renders text attribute as html so
            // &deg; is rendered
            htmlMode: true
          },
          // animation docs here:
          // https://www.zingchart.com/docs/tutorials/design-and-styling/chart-animation/#animation__effect
          animation: {
            effect: 'ANIMATION_EXPAND_BOTTOM',
            method: 'ANIMATION_STRONG_EASE_OUT',
            sequence: 'ANIMATION_BY_PLOT',
            speed: 275
          }
        },
        series: this.fSeriesPieAssociadosPorUF
      }
    },
    fChartDataAssociadosEfetivosPorUF() {
      return {
        type: 'pie',
        globals: {
          fontSize: 14
        },
        title: {
          text: 'Associados Efetivos',
          'font-size': '25px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
          // will force margin and padding on title
          // adjustLayout: true
        },
        legend: {
          draggable: true,
          // will force margin and padding on legen d
          adjustLayout: true
        },
        scaleR: {},
        // plot represents general series, or plots, styling
        plot: {
          // hoverstate
          tooltip: {
            // turn individual point tooltip off
            // visible: false,
            padding: '10 15',
            borderRadius: 3,
            // % symbol represents a token to insert a value. Full list here:
            // https://www.zingchart.com/docs/tutorials/chart-elements/zingchart-tokens/
            text: '%plot-text %kl teve %v associados efetivos',
            // htmlMode renders text attribute as html so
            // &deg; is rendered
            htmlMode: true
          },
          // animation docs here:
          // https://www.zingchart.com/docs/tutorials/design-and-styling/chart-animation/#animation__effect
          animation: {
            effect: 'ANIMATION_EXPAND_BOTTOM',
            method: 'ANIMATION_STRONG_EASE_OUT',
            sequence: 'ANIMATION_BY_PLOT',
            speed: 275
          }
        },
        series: this.fSeriesPieAssociadosEfetivosPorUF
      }
    },
    fChartDataAssociadosVinculadosPorUF() {
      return {
        type: 'pie',
        globals: {
          fontSize: 14
        },
        title: {
          text: 'Associados Vinculados',
          'font-size': '25px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
          // will force margin and padding on title
          // adjustLayout: true
        },
        legend: {
          draggable: true,
          // will force margin and padding on legen d
          adjustLayout: true
        },
        scaleR: {},
        // plot represents general series, or plots, styling
        plot: {
          // hoverstate
          tooltip: {
            // turn individual point tooltip off
            // visible: false,
            padding: '10 15',
            borderRadius: 3,
            // % symbol represents a token to insert a value. Full list here:
            // https://www.zingchart.com/docs/tutorials/chart-elements/zingchart-tokens/
            text: '%plot-text %kl teve %v associados vinculados',
            // htmlMode renders text attribute as html so
            // &deg; is rendered
            htmlMode: true
          },
          // animation docs here:
          // https://www.zingchart.com/docs/tutorials/design-and-styling/chart-animation/#animation__effect
          animation: {
            effect: 'ANIMATION_EXPAND_BOTTOM',
            method: 'ANIMATION_STRONG_EASE_OUT',
            sequence: 'ANIMATION_BY_PLOT',
            speed: 275
          }
        },
        series: this.fSeriesPieAssociadosVinculadosPorUF
      }
    }
  }
}
</script>

<style scoped>
.divTitle {
  text-align: justify;
  font-size: 40px;
  color: #1976d2;
  font-family: Arial;
  font-weight: bold;
}
</style>

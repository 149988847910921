<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="#2562A3"
    dark
    dense
    bottom
    height="20px"
  >
    <v-spacer />
    <span x-small class="caption white--text"
      >{{ fMsgAPIVersion }} &copy;{{ new Date().getFullYear() }}</span
    >
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',

  data() {
    return {
      fMsgAPIVersion: 'Controle de Adesão'
    }
  },

  mounted() {
    if (this.fApiVersion == undefined || this.fApiVersion == '') {
      this.$store
        .dispatch('checkAPIVersion')
        .then(lVersions => {
          this.fMsgAPIVersion =
            'APP Version: ' +
            lVersions.appversion +
            ' / API Version: ' +
            lVersions.apiversion
        })
        .catch(lAPIVersionError => {
          this.fMsgAPIVersion =
            'APP Version: ' +
            lAPIVersionError.appversion +
            ' / API Status: ' +
            lAPIVersionError.apiversion
        })
    } else {
      this.fMsgAPIVersion =
        'APP Version: ' +
        this.fAppVersion +
        ' / API Version: ' +
        this.fApiVersion
    }
  },

  computed: {
    ...mapGetters({
      fApiVersion: 'ApiVersion',
      fAppVersion: 'AppVersion'
    })
  }
}
</script>

<template>
  <div>
    <h5 class="display-1 info--text">
      Sistema de Controle de Adesões
    </h5>
    <br />
    <!--h3 class="subtitle-1 info--text">Versão: 1.00.20200407.0410</h3-->
    <h3 class="subtitle-1 info--text">Versão: {{ fAppVersion }}</h3>
    <br />
    <br />
  </div>
</template>

<script>
//import { mapGetters } from 'vuex'
export default {
  name: 'About',
  mounted() {
    this.$store
      .dispatch('checkAPIVersion')
      .then(lVersions => {
        this.fMsgAPIVersion =
          'APP Version: ' +
          lVersions.appversion +
          ' / API Version: ' +
          lVersions.apiversion
      })
      .catch(lAPIVersionError => {
        this.fMsgAPIVersion =
          'APP Version: ' +
          lAPIVersionError.appversion +
          ' / API Status: ' +
          lAPIVersionError.apiversion
      })
  }
  /*computed: {
    ...mapGetters({
      fAppVersion: 'AppVersion'
    })
  }*/
}
</script>

export default {
  CadastroList: aState => {
    return aState.fCadastroList
  },

  CadastroHeaders: aState => {
    return aState.fCadastroHeaders
  },

  Cadastro: aState => {
    return aState.fCadastro
  },

  CadastroNotChanged: aState => {
    return aState.fCadastroNotChanged
  },

  CadastroLoading: aState => {
    return aState.fCadastroLoading
  }
}

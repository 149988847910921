//import { _ConsoleLog } from '@/services/functions/view'

const _FillChartPieSeriesPorUF = (aState, aValueSeries) => {
  var lSeriesReturn = []
  for (var lItem in aValueSeries) {
    lSeriesReturn.push({
      values: [aValueSeries[lItem]],
      backgroundColor: aState.fSeriesPieBackGroundColorsUF[lItem],
      text: aState.fLabelsPieUF[lItem]
    })
  }
  return lSeriesReturn
}

export default {
  SET_DADOSDASHBOARD: (aState, aValue) => {
    aState.fDadosDashBoard = { ...aValue }
  },
  // CHART LINE
  SET_LABELSLINE: (aState, aValue) => {
    aState.fLabelsLine = [...aValue]
  },
  SET_SERIESLINEASSOCIADOS: (aState, aValueSeries) => {
    aState.fSeriesLineAssociados = [...aValueSeries]
  },
  SET_SERIESLINEASSOCIADOSEFETIVOS: (aState, aValueSeries) => {
    aState.fSeriesLineAssociadosEfetivos = [...aValueSeries]
  },
  SET_SERIESLINEASSOCIADOSVINCULADOS: (aState, aValueSeries) => {
    aState.fSeriesLineAssociadosVinculados = [...aValueSeries]
  },
  SET_TITLECHARTLINE: (aState, aValueSeries) => {
    aState.fTitleChartLine = aValueSeries
  },
  // CHART PIE
  SET_LABELSPIEUF: (aState, aValue) => {
    aState.fLabelsPieUF = { ...aValue }
  },
  SET_SERIESPIEASSOCIADOSPORUF: (aState, aValueSeries) => {
    aState.fSeriesPieAssociadosPorUF = _FillChartPieSeriesPorUF(
      aState,
      aValueSeries
    )
  },
  SET_SERIESPIEASSOCIADOSEFETIVOSPORUF: (aState, aValueSeries) => {
    aState.fSeriesPieAssociadosEfetivosPorUF = _FillChartPieSeriesPorUF(
      aState,
      aValueSeries
    )
  },
  SET_SERIESPIEASSOCIADOSVINCULADOSPORUF: (aState, aValueSeries) => {
    aState.fSeriesPieAssociadosVinculadosPorUF = _FillChartPieSeriesPorUF(
      aState,
      aValueSeries
    )
  },
  SET_TITLECHARTPIEPORUF: (aState, aValueSeries) => {
    aState.fTitleChartPiePorUF = aValueSeries
  },
  // CHART BAR
  SET_LABELSBARUF: (aState, aValue) => {
    aState.fLabelsBarUF = [...aValue]
  },
  SET_SERIESBARASSOCIADOSPORUF: (aState, aValueSeries) => {
    aState.fSeriesBarAssociadosPorUF = [...aValueSeries]
  },
  SET_SERIESBARASSOCIADOSEFETIVOSPORUF: (aState, aValueSeries) => {
    aState.fSeriesBarAssociadosEfetivosPorUF = [...aValueSeries]
  },
  SET_SERIESBARASSOCIADOSVINCULADOSPORUF: (aState, aValueSeries) => {
    aState.fSeriesBarAssociadosVinculadosPorUF = [...aValueSeries]
  },
  SET_TITLECHARTBARPORUF: (aState, aValueSeries) => {
    aState.fTitleChartBarPorUF = aValueSeries
  }
}

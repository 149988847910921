<template>
  <div>
    <v-row>
      <zingchart :data="fChartDataEvolucaoAssociadosPorUF"></zingchart>
    </v-row>
  </div>
</template>

<script>
//import { _ConsoleLog } from '@/services/functions/view'
import 'zingchart/es6'
import zingchartVue from 'zingchart-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChartBarAssociadosPorUF',

  components: {
    zingchart: zingchartVue
  },

  computed: {
    ...mapGetters({
      fLabelsBarUF: 'LabelsBarUF',
      fSeriesBarAssociadosPorUF: 'SeriesBarAssociadosPorUF',
      fSeriesBarAssociadosEfetivosPorUF: 'SeriesBarAssociadosEfetivosPorUF',
      fSeriesBarAssociadosVinculadosPorUF: 'SeriesBarAssociadosVinculadosPorUF',
      fTitleChartBarPorUF: 'TitleChartBarPorUF'
    }),
    fChartDataEvolucaoAssociadosPorUF() {
      return {
        type: 'bar',
        utc: true,
        title: {
          text: this.fTitleChartBarPorUF,
          'font-size': '40px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
        },
        plotarea: {
          margin: 'dynamic 45 60 dynamic'
        },
        legend: {
          layout: 'float',
          'background-color': 'none',
          'border-width': 0,
          shadow: 0,
          align: 'center',
          'adjust-layout': true,
          'toggle-action': 'remove',
          item: {
            padding: 7,
            marginRight: 17,
            cursor: 'hand'
          }
        },
        'scale-x': {
          labels: this.fLabelsBarUF
        },
        'scale-y': {
          'line-color': '#f6f7f8',
          shadow: 0,
          guide: {
            'line-style': 'dashed'
          },
          label: {
            text: 'Quantidade de Associados'
          },
          'minor-ticks': 0,
          'thousands-separator': ','
        },
        'crosshair-x': {
          'line-color': '#efefef',
          'plot-label': {
            'border-radius': '5px',
            'border-width': '1px',
            'border-color': '#f6f7f8',
            padding: '10px',
            'font-weight': 'bold'
          },
          'scale-label': {
            'font-color': '#000',
            'background-color': '#f6f7f8',
            'border-radius': '5px'
          }
        },
        tooltip: {
          visible: false
        },
        plot: {
          'value-box': {
            //Displays all data values by default.
          },
          highlight: true,
          'tooltip-text': '%t views: %v<br>%k',
          shadow: 0,
          'line-width': '2px',
          marker: {
            type: 'circle',
            size: 3
          },
          'highlight-state': {
            'line-width': 3
          },
          animation: {
            effect: 1,
            sequence: 2,
            speed: 100
          }
        },
        series: [
          {
            values: this.fSeriesBarAssociadosPorUF,
            text: 'Total Associados',
            'line-color': '#0D47A1',
            'legend-item': {
              'background-color': '#0D47A1',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#0D47A1',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69dbf1'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#0D47A1' // 007790
            }
          },

          {
            values: this.fSeriesBarAssociadosEfetivosPorUF,
            text: 'Associados Efetivos',
            'line-color': '#da534d',
            'legend-item': {
              'background-color': '#da534d',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#da534d',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69f2d0'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#da534d'
            }
          },

          {
            values: this.fSeriesBarAssociadosVinculadosPorUF,
            text: 'Associados Vinculados',
            'line-color': '#009872',
            'legend-item': {
              'background-color': '#009872',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#009872',
              'border-width': 1,
              shadow: 0,
              'border-color': '#faa39f'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#009872'
            }
          }
        ]
      }
    }
  }
}
</script>

import msgerroState from './msgerroState'
import msgerroMutations from './msgerroMutations'
import msgerroGetters from './msgerroGetters'
import msgerroActions from './msgerroActions'

export default {
  state: msgerroState,
  mutations: msgerroMutations,
  getters: msgerroGetters,
  actions: msgerroActions
}

export default {
  fCadastroList: [],

  fCadastroHeaders: [
    // objetos JSON com os cmapos que ser�o apresentados
    { text: '', sortable: false, value: 'actions', width: '10px' },
    {
      text: 'Nome',
      sortable: true,
      align: 'start',
      value: 'nm_nome'
    },
    {
      text: 'CPF',
      sortable: true,
      align: 'start',
      value: 'nr_cpf'
    },
    {
      text: 'Categoria Associativa',
      sortable: true,
      align: 'start',
      value: 'nm_tipoassociacao'
    },
    {
      text: 'E-mail Principal',
      sortable: true,
      align: 'start',
      value: 'nm_email'
    },
    {
      text: 'E-mail Secundário',
      sortable: true,
      align: 'start',
      value: 'nm_email2'
    },
    {
      text: 'Data da Associação',
      sortable: false,
      align: 'start',
      value: 'dthr_associacao'
    }
  ],

  fCadastroLoading: false,

  fCadastro: {
    id_cadastro: 0,
    nr_cpf: '',
    nm_nome: '',
    dt_nascimento: '',
    nm_mae: '',
    nm_endereco: '',
    nr_numero: '',
    nm_complemento: '',
    nm_bairro: '',
    nm_cidade: '',
    cd_uf: '',
    nr_cep: '',
    nm_profissao: '',
    fl_importado: '',
    fl_aceite: '',
    dthr_insert: '1899-12-30T00:00:00.000Z',
    nm_email: '',
    nm_password: '',
    fl_emailenviado: '',
    nm_token: '',
    nm_telefoneresidencial: '',
    nm_telefonecomercial: '',
    nm_telefonecelular: '',
    fl_declaracaogerada: '',
    fl_cadastroconfirmado: '',
    dthr_geracaodeclaracao: '1899-12-30T00:00:00.000Z',
    dthr_cadastroconfirmado: '1899-12-30T00:00:00.000Z',
    nm_email2: '',
    dthr_token: '1899-12-30T00:00:00.000Z',
    cd_tipoassociacao: 0,
    nm_instituicaoensino: '',
    nm_serie: '',
    nm_turma: '',
    cd_turno: 0,
    cd_sexo: '',
    cd_graduacao: 0,
    nm_curso: '',
    nm_nomeassociadovinculado: '',
    cd_parentesco: 0,
    fl_solicitacaocancelamento: '',
    dthr_solicitacaocancelamento: '1899-12-30T00:00:00.000Z',
    fl_confirmacaocancelamento: '',
    dthr_confirmacaocancelamento: '1899-12-30T00:00:00.000Z',
    fl_emailcancelamentoenviado: '',
    fl_confirmacaocancelamentosys: '',
    dthr_confirmacaocancelamentosys: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0,
    dthr_reg: '1899-12-30T00:00:00.000Z'
  },

  fCadastroNotChanged: {
    id_cadastro: 0,
    nr_cpf: '',
    nm_nome: '',
    dt_nascimento: '',
    nm_mae: '',
    nm_endereco: '',
    nr_numero: '',
    nm_complemento: '',
    nm_bairro: '',
    nm_cidade: '',
    cd_uf: '',
    nr_cep: '',
    nm_profissao: '',
    fl_importado: '',
    fl_aceite: '',
    dthr_insert: '1899-12-30T00:00:00.000Z',
    nm_email: '',
    nm_password: '',
    fl_emailenviado: '',
    nm_token: '',
    nm_telefoneresidencial: '',
    nm_telefonecomercial: '',
    nm_telefonecelular: '',
    fl_declaracaogerada: '',
    fl_cadastroconfirmado: '',
    dthr_geracaodeclaracao: '1899-12-30T00:00:00.000Z',
    dthr_cadastroconfirmado: '1899-12-30T00:00:00.000Z',
    nm_email2: '',
    dthr_token: '1899-12-30T00:00:00.000Z',
    cd_tipoassociacao: 0,
    nm_instituicaoensino: '',
    nm_serie: '',
    nm_turma: '',
    cd_turno: 0,
    cd_sexo: '',
    cd_graduacao: 0,
    nm_curso: '',
    nm_nomeassociadovinculado: '',
    cd_parentesco: 0,
    fl_solicitacaocancelamento: '',
    dthr_solicitacaocancelamento: '1899-12-30T00:00:00.000Z',
    fl_confirmacaocancelamento: '',
    dthr_confirmacaocancelamento: '1899-12-30T00:00:00.000Z',
    fl_emailcancelamentoenviado: '',
    fl_confirmacaocancelamentosys: '',
    dthr_confirmacaocancelamentosys: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0,
    dthr_reg: '1899-12-30T00:00:00.000Z'
  },

  fCadastroClean: {
    id_cadastro: 0,
    nr_cpf: '',
    nm_nome: '',
    dt_nascimento: '',
    nm_mae: '',
    nm_endereco: '',
    nr_numero: '',
    nm_complemento: '',
    nm_bairro: '',
    nm_cidade: '',
    cd_uf: '',
    nr_cep: '',
    nm_profissao: '',
    fl_importado: '',
    fl_aceite: '',
    dthr_insert: '1899-12-30T00:00:00.000Z',
    nm_email: '',
    nm_password: '',
    fl_emailenviado: '',
    nm_token: '',
    nm_telefoneresidencial: '',
    nm_telefonecomercial: '',
    nm_telefonecelular: '',
    fl_declaracaogerada: '',
    fl_cadastroconfirmado: '',
    dthr_geracaodeclaracao: '1899-12-30T00:00:00.000Z',
    dthr_cadastroconfirmado: '1899-12-30T00:00:00.000Z',
    nm_email2: '',
    dthr_token: '1899-12-30T00:00:00.000Z',
    cd_tipoassociacao: 0,
    nm_instituicaoensino: '',
    nm_serie: '',
    nm_turma: '',
    cd_turno: 0,
    cd_sexo: '',
    cd_graduacao: 0,
    nm_curso: '',
    nm_nomeassociadovinculado: '',
    cd_parentesco: 0,
    fl_solicitacaocancelamento: '',
    dthr_solicitacaocancelamento: '1899-12-30T00:00:00.000Z',
    fl_confirmacaocancelamento: '',
    dthr_confirmacaocancelamento: '1899-12-30T00:00:00.000Z',
    fl_emailcancelamentoenviado: '',
    fl_confirmacaocancelamentosys: '',
    dthr_confirmacaocancelamentosys: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0,
    dthr_reg: '1899-12-30T00:00:00.000Z'
  }
}

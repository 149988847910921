export default {
  fUsuarioList: [],

  fUsuarioHeaders: [
    // objetos JSON com os cmapos que ser�o apresentados
    { text: '', sortable: false, value: 'actions', width: '10px' },
    {
      text: 'Nome do Usuário',
      sortable: true,
      align: 'start',
      value: 'nm_nome'
    },
    {
      text: 'E-mail',
      sortable: true,
      align: 'start',
      value: 'nm_email'
    },
    {
      text: 'Login',
      sortable: true,
      align: 'start',
      value: 'cd_login'
    }
  ],

  fUsuario: {
    id_usuario: 0,
    nm_nome: '',
    nm_email: '',
    cd_login: '',
    nm_senha: '',
    id_tipousuario: 0,
    fl_ativo: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  },

  fUsuarioNotChanged: {
    id_usuario: 0,
    nm_nome: '',
    nm_email: '',
    cd_login: '',
    nm_senha: '',
    id_tipousuario: 0,
    fl_ativo: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  },

  fUsuarioClean: {
    id_usuario: 0,
    nm_nome: '',
    nm_email: '',
    cd_login: '',
    nm_senha: '',
    id_tipousuario: 0,
    fl_ativo: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  }
}

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/lib/locale/pt'

const opts = {
  lang: {
    current: 'pt',
    locales: { pt }
  }
}

Vue.use(Vuetify)

export default new Vuetify({ opts })

export default {
  TipoUsuarioList: aState => {
    return aState.fTipoUsuarioList
  },

  TipoUsuarioHeaders: aState => {
    return aState.fTipoUsuarioHeaders
  },

  TipoUsuario: aState => {
    return aState.fTipoUsuario
  },

  TipoUsuarioNotChanged: aState => {
    return aState.fTipoUsuarioNotChanged
  }
}

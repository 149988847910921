//import { _ConsoleLog } from '@/services/functions/view'

export default {
  SET_USUARIOLIST: (aState, aValue) => {
    aState.fUsuarioList = [...aValue]
  },

  SET_USUARIO: (aState, aValue) => {
    aState.fUsuario = { ...aValue }
    aState.fUsuarioNotChanged = { ...aValue }
  },

  CLEAR_USUARIO: aState => {
    aState.fUsuario = { ...aState.fUsuarioClean }
  }
}

<template>
  <!-- FORM DIALOG -->
  <!--v-dialog
    v-model="fShowDialogData"
    max-width="60%"
    max-height="95%"
    class="elevation-1"
    persistent
  -->
  <!--v-card width="100%"-->
  <v-card>
    <!--v-card-title class="info white--text">
      Tipo de Usuário
    </v-card-title -->
    <!--v-divider /-->
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="5" sm="5" md="5">
            <v-text-field
              dense
              v-model="fRecord.nm_descricao"
              label="Tipo de Usuário"
              required
              @change="onDataChanged"
              @keydown="onDataChanged"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3" sm="3" md="3">
            <v-text-field
              dense
              v-model="fRecord.nr_ordem"
              label="Ordem"
              required
              @change="onDataChanged"
              @keydown="onDataChanged"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider />
    <ButtonsDialogData
      :fInInsert="fInInsert"
      :fInEdit="fInEdit"
      :fShowBtnCustom01="false"
      :fDataChanged="fDataChanged"
      @onEventBtnCustom01Owner="onGoItemTipoUsuario"
      @onSaveRecordOwner="onSaveRecord"
      @onDeleteRecordOwner="onDeleteRecord"
      @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
      @onNewDataOwner="onNewData"
      @onCancelRecordChangedOwner="onCancelRecordChanged"
    ></ButtonsDialogData>
    <!-- /v-card-actions -->
  </v-card>
  <!-- /v-dialog -->
</template>
<script>
import ButtonsDialogData from '@/components/ButtonsDialogData.vue'

export default {
  name: 'TipoUsuarioDialogData',

  components: {
    ButtonsDialogData
  },

  props: {
    fShowDialogData: Boolean,
    fRecord: Object,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fDataChanged: Boolean
  },

  methods: {
    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onDataChanged() {
      this.$emit('onDataChangedOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onGoItemTipoUsuario() {
      this.$emit('onGoItemTipoUsuarioOwner')
    }
  }
}
</script>

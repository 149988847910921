import api from '@/services/api'
import { _ConsoleLog } from '@/services/functions/view'

const cPathResource = '/menu'

export default {
  clearMenuList(aContext) {
    var lURL = cPathResource + '/' + aContext.state.fIdOpcaoPai
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          if (lResponse.status == 204) {
            aContext.commit('SET_MENULIST', [])
          } else {
            aContext.commit('SET_MENULIST', lResponse.data)
          }
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_MENULIST', [])
          reject(lError)
        })
    })
  },

  clearMenu(aContext) {
    aContext.commit('SET_MENU', aContext.state.fMenuClean)
  },

  getMenuByID(aContext, aRecord) {
    var lURL =
      cPathResource + '/' + aContext.state.fIdOpcaoPai + '/' + aRecord.id_opcao
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_MENU', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  deleteMenu(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_opcao
    return new Promise((resolve, reject) => {
      api
        .delete(`${lURL}`)
        .then(() => {
          aContext.commit('CLEAR_MENU')
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  insertMenu(aContext, aRecord) {
    return new Promise((resolve, reject) => {
      api
        .post(cPathResource, aRecord)
        .then(lResponse => {
          aContext.commit('SET_MENU', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateMenu(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_opcao
    return new Promise((resolve, reject) => {
      api
        .put(`${lURL}`, aRecord)
        .then(lResponse => {
          aContext.commit('SET_MENU', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  searchMenuListByName(aContext, aQueryString) {
    var lURL = cPathResource + 'search' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_MENULIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  rollBackMenu(aContext) {
    aContext.commit('SET_MENU', aContext.state.fMenuNotChanged)
  },

  setIdOpcaoPaiRecordCurrent(aContext) {
    aContext.commit('SET_MENUIDOPCAOPAI')
  },

  setIdOpcaoPaiBackRecord(aContext) {
    aContext.commit('SET_MENUIDOPCAOPAIBACKRECORD')
  },

  setMainMenuAppTemp(aContext) {
    _ConsoleLog(
      'ACTION: setMainMenuAppTemp - aContext.rootGetters.TypeUser: ' +
        aContext.rootGetters.TypeUser
    )
    aContext.commit('SET_MAINMENUAPPTEMP', aContext.rootGetters.TypeUser)
  },

  getMainMenuApp(aContext) {
    return new Promise((resolve, reject) => {
      api
        .get('/mainmenu')
        .then(lResponse => {
          aContext.commit('SET_MAINMENUAPP', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  }
}

//import { _getTyperUser } from '@/services/functions/crud'
import { cNivelZeroMenu } from '@/services/consts'
import {
  ADMAPP,
  ADMEMPRESA,
  GESTOREMPRESA,
  USEREMPRESA
} from '@/services/consts'
import { _ConsoleLog } from '@/services/functions/view'
//import vm from '@/main'

export default {
  SET_MENULIST: (aState, aValue) => {
    aState.fMenuList = [...aValue]
  },

  SET_MENU: (aState, aValue) => {
    aState.fMenu = { ...aValue }
    aState.fMenuNotChanged = { ...aValue }
  },

  SET_MENUIDOPCAOPAI: aState => {
    aState.fTempMenuList.push(aState.fMenu)
    aState.fIdOpcaoPai = aState.fMenu.id_opcao // id_opcao
    if (aState.fIdOpcaoPai == cNivelZeroMenu) {
      aState.fNmTextoPai = ''
    } else {
      aState.fNmTextoPai = aState.fMenu.nm_texto
    }
  },

  SET_MENUIDOPCAOPAIBACKRECORD: aState => {
    if (aState.fTempMenuList.length == 0) {
      aState.fIdOpcaoPai = cNivelZeroMenu
      aState.fNmTextoPai = ''
    } else {
      aState.fIdOpcaoPai = aState.fMenu.id_opcaopai
      if (aState.fIdOpcaoPai == cNivelZeroMenu) {
        aState.fNmTextoPai = ''
      } else {
        aState.fNmTextoPai = aState.fMenu.nm_texto
      }
      aState.fTempMenuList.pop()
    }
  },

  CLEAR_MENU: aState => {
    aState.fMenu = { ...aState.fMenuClean }
  },

  SET_MAINMENUAPP: (aState, aValue) => {
    aState.fMainMenuApp = { ...aValue }
  },

  SET_MAINMENUAPPTEMP: (aState, aTypeUser) => {
    _ConsoleLog('SET_MAINMENUAPPTEMP aTypeUser: ' + aTypeUser)
    switch (aTypeUser) {
      case ADMAPP:
        aState.fMainMenuApp = { ...aState.fMainMenuAppAdm }
        break
      case ADMEMPRESA:
        aState.fMainMenuApp = { ...aState.fMainMenuAppAdmEmpresa }
        break
      case GESTOREMPRESA:
        aState.fMainMenuApp = { ...aState.fMainMenuAppGestorEmpresa }
        break
      case USEREMPRESA:
        aState.fMainMenuApp = { ...aState.fMainMenuAppUserEmpresa }
        break
    }

    /*if (aTypeUser == ADM) aState.fMainMenuApp = { ...aState.fMainMenuAppAdm }
    else aState.fMainMenuApp = { ...aState.fMainMenuAppUser }*/
  }
}

export default {
  CategoriaProfissionalList: aState => {
    return aState.fCategoriaProfissionalList
  },

  CategoriaProfissionalHeaders: aState => {
    return aState.fCategoriaProfissionalHeaders
  },

  CategoriaProfissional: aState => {
    return aState.fCategoriaProfissional
  },

  CategoriaProfissionalNotChanged: aState => {
    return aState.fCategoriaProfissionalNotChanged
  }
}

export default {
  fMsgErroList: [],

  fMsgErroHeaders: [
    // objetos JSON com os cmapos que ser�o apresentados
    { text: '', sortable: false, value: 'actions', width: '10px', fixed: true },
    {
      text: 'Palavra-Chave',
      sortable: true,
      align: 'start',
      value: 'nm_constraint'
    },
    {
      text: 'Mensagem de Erro',
      sortable: true,
      align: 'start',
      value: 'mm_msgerro'
    }
  ],

  fMsgErro: {
    id_msgerro: 0,
    nm_constraint: '',
    mm_msgerro: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  },

  fMsgErroNotChanged: {
    id_msgerro: 0,
    nm_constraint: '',
    mm_msgerro: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  },

  fMsgErroClean: {
    id_msgerro: 0,
    nm_constraint: '',
    mm_msgerro: '',
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0
  }
}

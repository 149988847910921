export default {
  SET_CADASTROLIST: (aState, aValue) => {
    aState.fCadastroList = [...aValue]
  },

  SET_CADASTRO: (aState, aValue) => {
    aState.fCadastro = { ...aValue }
    aState.fCadastroNotChanged = { ...aValue }
  },

  CLEAR_CADASTRO: aState => {
    aState.fCadastro = { ...aState.fCadastroClean }
  },

  SET_CADASTROLOADINGDATATABLE: (aState, aLoading) => {
    aState.fCadastroLoading = aLoading
  }
}

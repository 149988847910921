import menuState from './menuState'
import menuMutations from './menuMutations'
import menuGetters from './menuGetters'
import menuActions from './menuActions'

export default {
  state: menuState,
  mutations: menuMutations,
  getters: menuGetters,
  actions: menuActions
}

import { APPVERSION } from '@/services/consts'

export default {
  userData: {
    nameuser: '',
    iduser: 0,
    typeuser: 0
  },
  userDataClean: {
    nameuser: '',
    iduser: 0,
    typeuser: 0
  },
  apiversion: '',
  appversion: APPVERSION,
  token: ''
}

import authState from './authState'
import authMutations from './authMutations'
import authGetters from './authGetters'
import authActions from './authActions'

export default {
  state: authState,
  mutations: authMutations,
  getters: authGetters,
  actions: authActions
}

export default {
  SET_CATEGORIAPROFISSIONALLIST: (aState, aValue) => {
    aState.fCategoriaProfissionalList = [...aValue]
  },

  SET_CATEGORIAPROFISSIONAL: (aState, aValue) => {
    aState.fCategoriaProfissional = { ...aValue }
    aState.fCategoriaProfissionalNotChanged = { ...aValue }
  },

  CLEAR_CATEGORIAPROFISSIONAL: aState => {
    aState.fCategoriaProfissional = { ...aState.fCategoriaProfissionalClean }
  }
}

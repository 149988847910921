import cadastroState from './cadastroState'
import cadastroMutations from './cadastroMutations'
import cadastroGetters from './cadastroGetters'
import cadastroActions from './cadastroActions'

export default {
  state: cadastroState,
  mutations: cadastroMutations,
  getters: cadastroGetters,
  actions: cadastroActions
}

import Vue from 'vue'
import VueRouter from 'vue-router'

import Logout from '@/components/Logout'

import About from '@/views/About.vue'
import Login from '@/views/Login.vue'
import MainMenu from '@/views/MainMenu'
import MenuListData from '@/views/crud/menu/MenuListData'

import TipoUsuarioListData from '@/views/crud/tipousuario/TipoUsuarioListData'
import MsgErroListData from '@/views/crud/msgerro/MsgErroListData'
import CategoriaProfissionalListData from '@/views/crud/categoriaprofissional/CategoriaProfissionalListData'
import CadastroListData from '@/views/crud/cadastro/CadastroListData'
import UsuarioListData from '@/views/crud/usuario/UsuarioListData'

import DashBoard from '@/views/DashBoard/DashBoard'
import DashBoardEvolucaoAssociados from '@/views/DashBoard/DashBoardEvolucaoAssociados'

import TestGraphic from '@/views/TestGraphic'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Login },
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  {
    path: '/mainmenu',
    component: MainMenu,
    children: [
      { path: 'dashboard', component: DashBoard },
      {
        path: 'dashboardevolucaoassociados',
        component: DashBoardEvolucaoAssociados
      },
      { path: 'testgraphic', component: TestGraphic },
      //{ path: 'cadastro', component: CadastroListData },
      //{ path: 'cadastro', component: ViewCadCadastro },
      { path: 'about', component: About },
      { path: 'menu', component: MenuListData },
      { path: 'tipousuario', component: TipoUsuarioListData },
      { path: 'usuario', component: UsuarioListData },
      { path: 'msgerro', component: MsgErroListData },
      {
        path: 'categoriaprofissional',
        component: CategoriaProfissionalListData
      },
      { path: 'cadastro', component: CadastroListData }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

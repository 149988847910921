//import { _ConsoleLog } from '@/services/functions/view'

export default {
  MenuList: aState => {
    return aState.fMenuList
  },

  MenuHeaders: aState => {
    return aState.fMenuHeaders
  },

  Menu: aState => {
    return aState.fMenu
  },

  MenuNotChanged: aState => {
    return aState.fMenuNotChanged
  },

  IdOpcaoPai: aState => {
    return aState.fIdOpcaoPai
  },

  NmTextoPai: aState => {
    return aState.fNmTextoPai
  },

  MainMenuApp: aState => {
    /*_ConsoleLog('aGetters: ' + aGetters)
    _ConsoleLog('aRootState: ' + aRootState)
    _ConsoleLog('aRootGetters[Auth/TypeUser]: ' + aRootGetters['Auth/TypeUser'])
    _ConsoleLog(
      'GETTERS (MainMenuApp / aState.fMainMenuApp) ' + aState.fMainMenuApp
    )*/
    return aState.fMainMenuApp
  }
}

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import mystate from './mystate'
import Auth from './modulos/auth'
import Menu from './modulos/menu'
import TipoUsuario from './modulos/tipousuario'
import MsgErro from './modulos/msgerro'
import CategoriaProfissional from './modulos/categoriaprofissional'
import Cadastro from './modulos/cadastro'
import Usuario from './modulos/usuario'
import DashBoard from './modulos/dashboard'

export default new Vuex.Store({
  state: mystate,
  modules: {
    Auth,
    Menu,
    TipoUsuario,
    MsgErro,
    CategoriaProfissional,
    Cadastro,
    Usuario,
    DashBoard
  }
})

export default {
  IdUserLogged: aState => {
    return aState.userData.iduser
  },
  NameUser: aState => {
    return aState.userData.nameuser
  },
  TypeUser: aState => {
    return aState.userData.typeuser
  },
  ApiVersion: aState => {
    return aState.apiversion
  },
  AppVersion: aState => {
    return aState.appversion
  }
}

import api from '@/services/api'
//import { _ConsoleLog } from '@/services/functions/view'

const cPathResource = '/dashboard'

export default {
  updateDashBoard(aContext) {
    return new Promise((resolve, reject) => {
      api
        .get(cPathResource)
        .then(lResponse => {
          aContext.commit('SET_DADOSDASHBOARD', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          aContext.commit(
            'SET_DADOSDASHBOARD',
            aContext.state.fDadosDashBoardClean
          )
          reject(lError)
        })
    })
  },

  updateChartDataAnoAtual(aContext, ADatasPeriodo) {
    var lURL =
      '/dashboard/evolucaoassociados/' +
      ADatasPeriodo.DtIni +
      '/' +
      ADatasPeriodo.DtFim

    aContext.commit(
      'SET_TITLECHARTLINE',
      'Associações no Período: ' +
        ADatasPeriodo.DtIni.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtIni.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtIni.substring(4) +
        ' a ' +
        ADatasPeriodo.DtFim.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtFim.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtFim.substring(4)
    )

    /*
    aContext.state.fTitleChartLine =
      'Associações no Período: ' +
      ADatasPeriodo.DtIni.substring(0, 2) +
      '/' +
      ADatasPeriodo.DtIni.substring(2, 4) +
      '/' +
      ADatasPeriodo.DtIni.substring(4) +
      ' a ' +
      ADatasPeriodo.DtFim.substring(0, 2) +
      '/' +
      ADatasPeriodo.DtFim.substring(2, 4) +
      '/' +
      ADatasPeriodo.DtFim.substring(4)
    */
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          // CHART LINE
          aContext.commit('SET_LABELSLINE', lResponse.data.Meses)
          aContext.commit('SET_SERIESLINEASSOCIADOS', lResponse.data.Associados)
          aContext.commit(
            'SET_SERIESLINEASSOCIADOSEFETIVOS',
            lResponse.data.Efetivos
          )
          aContext.commit(
            'SET_SERIESLINEASSOCIADOSVINCULADOS',
            lResponse.data.Vinculados
          )
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateEvolucaoAssociadosPorPeriodo(aContext, ADatasPeriodo) {
    var lURL =
      '/dashboard/evolucaoassociadosporperiodo/' +
      ADatasPeriodo.DtIni +
      '/' +
      ADatasPeriodo.DtFim

    aContext.commit(
      'SET_TITLECHARTLINE',
      'Associações no Período: ' +
        ADatasPeriodo.DtIni.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtIni.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtIni.substring(4) +
        ' a ' +
        ADatasPeriodo.DtFim.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtFim.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtFim.substring(4)
    )
    aContext.commit(
      'SET_TITLECHARTBARPORUF',
      'Associações Por UF no Período ' +
        ADatasPeriodo.DtIni.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtIni.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtIni.substring(4) +
        ' a ' +
        ADatasPeriodo.DtFim.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtFim.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtFim.substring(4)
    )
    /*aContext.state.fTitleChartBarPorUF =
      'Associações Por UF no Período ' +
      ADatasPeriodo.DtIni.substring(0, 2) +
      '/' +
      ADatasPeriodo.DtIni.substring(2, 4) +
      '/' +
      ADatasPeriodo.DtIni.substring(4) +
      ' a ' +
      ADatasPeriodo.DtFim.substring(0, 2) +
      '/' +
      ADatasPeriodo.DtFim.substring(2, 4) +
      '/' +
      ADatasPeriodo.DtFim.substring(4)*/

    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_LABELSLINE', lResponse.data.Datas)
          aContext.commit('SET_SERIESLINEASSOCIADOS', lResponse.data.Associados)
          aContext.commit(
            'SET_SERIESLINEASSOCIADOSEFETIVOS',
            lResponse.data.Efetivos
          )
          aContext.commit(
            'SET_SERIESLINEASSOCIADOSVINCULADOS',
            lResponse.data.Vinculados
          )
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateEvolucaoAssociadosPorPeriodoPorUf(aContext, ADatasPeriodo) {
    var lURL =
      '/dashboard/evolucaoassociadosporuf/' +
      ADatasPeriodo.DtIni +
      '/' +
      ADatasPeriodo.DtFim

    aContext.commit(
      'SET_TITLECHARTPIEPORUF',
      (aContext.state.fTitleChartPiePorUF =
        'Associações Por UF no Período ' +
        ADatasPeriodo.DtIni.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtIni.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtIni.substring(4) +
        ' a ' +
        ADatasPeriodo.DtFim.substring(0, 2) +
        '/' +
        ADatasPeriodo.DtFim.substring(2, 4) +
        '/' +
        ADatasPeriodo.DtFim.substring(4))
    )
    /*aContext.state.fTitleChartPiePorUF =
      'Associações Por UF no Período ' +
      ADatasPeriodo.DtIni.substring(0, 2) +
      '/' +
      ADatasPeriodo.DtIni.substring(2, 4) +
      '/' +
      ADatasPeriodo.DtIni.substring(4) +
      ' a ' +
      ADatasPeriodo.DtFim.substring(0, 2) +
      '/' +
      ADatasPeriodo.DtFim.substring(2, 4) +
      '/' +
      ADatasPeriodo.DtFim.substring(4)*/

    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          // CHART PIE
          aContext.commit('SET_LABELSPIEUF', lResponse.data.UFs)
          aContext.commit(
            'SET_SERIESPIEASSOCIADOSPORUF',
            lResponse.data.Associados
          )
          aContext.commit(
            'SET_SERIESPIEASSOCIADOSEFETIVOSPORUF',
            lResponse.data.Efetivos
          )
          aContext.commit(
            'SET_SERIESPIEASSOCIADOSVINCULADOSPORUF',
            lResponse.data.Vinculados
          )
          // CHART BAR
          aContext.commit('SET_LABELSBARUF', lResponse.data.UFs)
          aContext.commit(
            'SET_SERIESBARASSOCIADOSPORUF',
            lResponse.data.Associados
          )
          aContext.commit(
            'SET_SERIESBARASSOCIADOSEFETIVOSPORUF',
            lResponse.data.Efetivos
          )
          aContext.commit(
            'SET_SERIESBARASSOCIADOSVINCULADOSPORUF',
            lResponse.data.Vinculados
          )
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_LABELSPIEUF', [])
          aContext.commit('SET_SERIESPIEASSOCIADOSPORUF', [])
          aContext.commit('SET_SERIESPIEASSOCIADOSEFETIVOSPORUF', [])
          aContext.commit('SET_SERIESPIEASSOCIADOSVINCULADOSPORUF', [])
          reject(lError)
        })
    })
  }
}

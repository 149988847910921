<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card width="100%">
        <v-card-title class="info white--text">
          Evolução das Associações
        </v-card-title>
        <v-card-text class="info--text">
          <v-row>
            <v-col cols="2" sm="1" md="2"
              ><DatePickerWithCalendar
                :fDtEdit="fDtIniEdit"
                fLabel="Data Inicial"
                @onUpdateDateOwner="onUpdateDtIni"
              />
            </v-col>
            <v-col cols="2" sm="1" md="2"
              ><DatePickerWithCalendar
                :fDtEdit="fDtFimEdit"
                fLabel="Data Final"
                @onUpdateDateOwner="onUpdateDtFim"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-checkbox
                v-model="fDatasPeriodo.AgruparPorMes"
                false-value="F"
                true-value="T"
                class="mx-2"
                label="Agrupar por Mês"
              ></v-checkbox>
            </v-col>
            <v-col cols="4" sm="3" md="3">
              <v-btn
                small
                class="blue lighten-5 info--text"
                @click="onUpdateDataDashBoard"
              >
                <v-icon>mdi-refresh</v-icon>Atualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-col cols="4" sm="9" md="10">
            <h4>
              <a href="#" @click="onBackToDashBoard()"
                >Voltar para o DashBoard</a
              >
            </h4>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <ChartLineAssociados />
        </v-card-text>
        <v-card-text>
          <ChartBarAssociadosPorUF />
        </v-card-text>
        <v-card-text>
          <ChartPieAssociadosPorUF />
        </v-card-text>
        <v-card-text>
          <v-col cols="4" sm="9" md="10">
            <h4>
              <a href="#" @click="onBackToDashBoard()"
                >Voltar para o DashBoard</a
              >
            </h4>
          </v-col>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
//import 'zingchart/es6'
//import zingchartVue from 'zingchart-vue'
import { _ConsoleLog } from '@/services/functions/view'
//import { mapGetters } from 'vuex'
import DatePickerWithCalendar from '@/components/DatePickerWithCalendar'
import ChartLineAssociados from '@/views/DashBoard/Charts/ChartLineAssociados'
import ChartBarAssociadosPorUF from '@/views/DashBoard/Charts/ChartBarAssociadosPorUF'
import ChartPieAssociadosPorUF from '@/views/DashBoard/Charts/ChartPieAssociadosPorUF'
import { cTrueStrChar } from '@/services/consts'

export default {
  name: 'DashBoardEvolucaoAssociados',

  components: {
    //zingchart: zingchartVue,
    DatePickerWithCalendar,
    ChartLineAssociados,
    ChartBarAssociadosPorUF,
    ChartPieAssociadosPorUF
  },

  data() {
    return {
      fDatasPeriodo: { DtIni: '', DtFim: '', AgruparPorMes: 'F' },
      fDtIniEdit: '',
      fDtFimEdit: ''
    }
  },

  mounted() {
    var lDateToday = new Date()
    var lDateTodayStr = lDateToday.toLocaleString()

    this.fDtIniEdit = '01' + lDateTodayStr.substring(2, 10)
    this.fDtFimEdit = lDateTodayStr.substring(0, 10)

    this.fDatasPeriodo.DtIni =
      '01' + lDateTodayStr.substring(3, 5) + lDateTodayStr.substring(6, 10)
    this.fDatasPeriodo.DtFim =
      lDateTodayStr.substring(0, 2) +
      lDateTodayStr.substring(3, 5) +
      lDateTodayStr.substring(6, 10)

    this._UpdateChartData()
  },

  methods: {
    _UpdateChartData() {
      if (this.fDatasPeriodo.AgruparPorMes == cTrueStrChar) {
        this._UpdateChartEvolucaoAssociadosAnoAtual()
      } else {
        this._updateChartEvolucaoAssociadosPorPeriodo()
      }
      this._updateChartEvolucaoAssociadosPorPeriodoPorUF()
    },

    _UpdateChartEvolucaoAssociadosAnoAtual() {
      this.$store
        .dispatch('updateChartDataAnoAtual', this.fDatasPeriodo)
        .then(lResponse => {
          _ConsoleLog('updateChartDataAnoAtual: ' + lResponse)
          /*this.fLabels = [...lResponse.Meses]
          this.fAssociados = [...lResponse.Associados]
          this.fEfetivos = [...lResponse.Efetivos]
          this.fVinculados = [...lResponse.Vinculados]*/
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
    },

    _updateChartEvolucaoAssociadosPorPeriodo() {
      this.$store.dispatch('updateDashBoard')
      this.$store
        .dispatch('updateEvolucaoAssociadosPorPeriodo', this.fDatasPeriodo)
        .then(lResponse => {
          _ConsoleLog('updateEvolucaoAssociadosPorPeriodo: ' + lResponse)
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
    },

    _updateChartEvolucaoAssociadosPorPeriodoPorUF() {
      this.$store
        .dispatch('updateEvolucaoAssociadosPorPeriodoPorUf', this.fDatasPeriodo)
        .then(lResponse => {
          _ConsoleLog('updateEvolucaoAssociadosPorPeriodoPorUf: ' + lResponse)
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
    },

    onUpdateDtIni(aDate) {
      this.fDatasPeriodo.DtIni = aDate
      this.fDtIniEdit =
        this.fDatasPeriodo.DtIni.substring(0, 2) +
        '/' +
        this.fDatasPeriodo.DtIni.substring(2, 4) +
        '/' +
        this.fDatasPeriodo.DtIni.substring(4)
    },

    onUpdateDtFim(aDate) {
      this.fDatasPeriodo.DtFim = aDate
      this.fDtFimEdit =
        this.fDatasPeriodo.DtFim.substring(0, 2) +
        '/' +
        this.fDatasPeriodo.DtFim.substring(2, 4) +
        '/' +
        this.fDatasPeriodo.DtFim.substring(4)
    },

    onUpdateDataDashBoard() {
      this._UpdateChartData()
    },

    onBackToDashBoard() {
      this.$router.push('/mainmenu/dashboard')
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>

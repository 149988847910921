import api, { setToken } from '@/services/api'
import { _ConsoleLog } from '@/services/functions/view'

export default {
  clearToken(aContext) {
    if (aContext.state.token != '') {
      aContext.state.token = ''
      setToken(aContext.state.token)
    }
  },

  doLoginApi(aContext, aInfoLogin) {
    return new Promise((resolve, reject) => {
      api
        .post('/login', aInfoLogin)
        .then(lResponse => {
          _ConsoleLog(lResponse.data)
          if (
            lResponse.data.Erro != undefined &&
            lResponse.data.Token == undefined
          ) {
            aContext.commit('SET_INFODATALOGGED', { Token: '' })
            resolve(false)
          } else {
            _ConsoleLog('CHAMANDO SET_INFODATALOGGED ')
            aContext.commit('SET_INFODATALOGGED', lResponse.data)
            resolve(true)
          }
        })
        .catch(() => {
          aContext.commit('SET_INFODATALOGGED', { Token: '' })
          reject(false)
        })
    })
  },

  getTyperUser(aContext) {
    _ConsoleLog('ACTION getTyperUser: ' + aContext.userData.typeuser)
    return aContext.userData.typeuser
  },

  checkAPIVersion(aContext) {
    return new Promise((resolve, reject) => {
      if (aContext.state.token != '') {
        api
          .get('/auth/status')
          .then(lResponse => {
            aContext.commit('SET_INFOAPIVERSION', lResponse.data.API_VERSION)
            resolve({
              appversion: aContext.state.appversion,
              apiversion: lResponse.data.Version
            })
          })
          .catch(() => {
            aContext.commit('SET_INFOAPIVERSION', 'OffLine')
            reject({
              appversion: aContext.state.appversion,
              apiversion: 'OffLine'
            })
          })
      } else {
        api
          .get('/status')
          .then(lResponse => {
            aContext.commit('SET_INFOAPIVERSION', lResponse.data.API_VERSION)
            resolve({
              appversion: aContext.state.appversion,
              apiversion: lResponse.data.Version
            })
          })
          .catch(() => {
            aContext.commit('SET_INFOAPIVERSION', 'OffLine')
            reject({
              appversion: aContext.state.appversion,
              apiversion: 'OffLine'
            })
          })
      }
    })
  }
}

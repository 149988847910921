import { _ConsoleLog } from '@/services/functions/view'
import api from '@/services/api'

const cPathResource = '/usuario'

export default {
  clearUsuarioList(aContext) {
    var lURL = cPathResource
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          if (lResponse.status == 204) {
            aContext.commit('SET_USUARIOLIST', [])
          } else {
            aContext.commit('SET_USUARIOLIST', lResponse.data)
          }
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_USUARIOLIST', [])
          reject(lError)
        })
    })
  },

  clearUsuario(aContext) {
    //aContext.commit('SET_USUARIO', aContext.state.fUsuarioClean)
    _ConsoleLog('ACTION CLEAR_USUARIO aContext: ' + aContext)
    _ConsoleLog(
      'ACTION CLEAR_USUARIO aContext.aState.fUsuario: ' +
        aContext.state.fUsuario.nm_email
    )
    _ConsoleLog(
      'ACTION CLEAR_USUARIO aState.fUsuarioClean: ' +
        aContext.state.fUsuarioClean.nm_email
    )
    aContext.commit('CLEAR_USUARIO')
    _ConsoleLog(
      'ACTION CLEAR_USUARIO aContext.aState.fUsuario: ' +
        aContext.state.fUsuario.nm_email
    )
  },

  getUsuarioByID(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_usuario
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_USUARIO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  deleteUsuario(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_usuario
    return new Promise((resolve, reject) => {
      api
        .delete(`${lURL}`)
        .then(() => {
          aContext.commit('CLEAR_USUARIO')
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  insertUsuario(aContext, aRecord) {
    return new Promise((resolve, reject) => {
      api
        .post(cPathResource, aRecord)
        .then(lResponse => {
          aContext.commit('SET_USUARIO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateUsuario(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_usuario
    return new Promise((resolve, reject) => {
      api
        .put(`${lURL}`, aRecord)
        .then(lResponse => {
          aContext.commit('SET_USUARIO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  /*
  searchUsuarioListByName(aContext, aQueryString) {
    var lURL = cPathResource + 'search' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_USUARIOLIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  */
  rollBackUsuario(aContext) {
    aContext.commit('SET_USUARIO', aContext.state.fUsuarioNotChanged)
  }
}

import { cNivelZeroMenu } from '@/services/consts'

export default {
  fMainMenuApp: [],

  fMainMenuAppAdm: [
    {
      text: 'DashBoard',
      link: '/mainmenu/dashboard',
      icon: 'mdi-view-dashboard'
    },
    {
      text: 'DashBoard Grafico',
      link: '/mainmenu/dashboardevolucaoassociados',
      icon: 'mdi-view-dashboard'
    },
    {
      text: 'Grafico',
      link: '/mainmenu/testgraphic',
      icon: 'mdi-view-dashboard'
    },
    {
      text: 'Associados Online',
      link: '/mainmenu/cadastro',
      icon: 'mdi-account'
    },
    {
      text: 'Cadastro',
      icon: 'mdi-table-edit',
      children: [
        {
          text: 'Categoria Profissional',
          link: '/mainmenu/categoriaprofissional'
        }
        /*{ text: 'Operadoras', link: '#' },
        { text: 'Planos', link: '#' },
        { text: 'Faixas Etárias', link: '#' },
        { text: 'Corretores', link: '#' },
        { text: 'Contas Bancárias', link: '#' },
        { text: 'Tabelas de Preços', link: '#' },
        { text: 'Supervisores', link: '#' },
        { text: 'Subprodutores', link: '#' },
        { text: 'Empresas de Clientes', link: '#' }*/
      ]
    },
    /*{
      text: 'Financeiro',
      icon: 'mdi-currency-usd',
      children: [
        { text: 'Parcelas/Boletos', link: '#' },
        { text: 'Conciliação Bancária', link: '#' },
        { text: 'Geração Remessa Bancária', link: '#' }
      ]
    },
    {
      text: 'Relatórios',
      icon: 'mdi-printer',
      children: [
        { text: 'Titular e Dependentes', link: '#' },
        { text: 'Boletos Baixados', link: '#' },
        { text: 'Boletos NÃO Baixados', link: '#' },
        { text: 'Pagamentos em Atraso', link: '#' },
        { text: 'Pagamentos Efetuados', link: '#' },
        { text: 'Pagamentos em Aberto', link: '#' },
        { text: 'Comissões', link: '#' }
      ]
    },*/
    {
      text: 'Administração',
      icon: 'mdi-sitemap',
      children: [
        { text: 'Tipos de Usuário', link: '/mainmenu/tipousuario' },
        { text: 'Usuário', link: '/mainmenu/usuario' },
        { text: 'Menu', link: '/mainmenu/menu' },
        { text: 'Mensagens de Erro', link: '/mainmenu/msgerro' }
      ]
    },
    /*{
      text: 'Sobre',
      link: '/mainmenu/about',
      icon: 'mdi-information-outline'
    },*/
    { text: 'Sair', link: '/', icon: 'mdi-logout-variant' }
  ],
  fMainMenuAppAdmEmpresa: [
    {
      text: 'DashBoard',
      link: '/mainmenu/dashboard',
      icon: 'mdi-view-dashboard'
    },
    {
      text: 'Associados Online',
      link: '/mainmenu/cadastro',
      icon: 'mdi-account'
    },
    { text: 'Sair', link: '/', icon: 'mdi-logout-variant' }
  ],
  fMainMenuAppGestorEmpresa: [
    {
      text: 'Associados Online',
      link: '/mainmenu/cadastro',
      icon: 'mdi-account'
    },
    { text: 'Sair', link: '/', icon: 'mdi-logout-variant' }
  ],
  fMainMenuAppUserEmpresa: [
    {
      text: 'Associados Online',
      link: '/mainmenu/cadastro',
      icon: 'mdi-account'
    },
    { text: 'Sair', link: '/', icon: 'mdi-logout-variant' }
  ],

  fMenuList: [],

  fMenuHeaders: [
    { text: '', sortable: false, value: 'actions', width: '10px', fixed: true },
    {
      text: 'Descrição Opção Menu',
      sortable: true,
      align: 'start',
      value: 'nm_texto'
    },
    {
      text: 'Ordem no Menu',
      sortable: true,
      align: 'start',
      value: 'nr_ordem'
    }
  ],

  /*{
    ID_OPCAO: 0,
    NM_TEXTO: '',
    NM_LINK: '',
    ID_OPCAOPAI: 0,
    NR_ORDEM: 0,
    NM_CLASSE: '',
    NR_LARGURA: '',
    DTHR_REG: '',
    id_usuariosistema: 0,
    FL_VISIVELNOMENU: '',
    NM_ICONE: ''
  },*/
  fMenu: {
    id_opcao: 0,
    nm_texto: '',
    nm_link: '',
    id_opcaopai: 0,
    nr_ordem: 0,
    nm_classe: '',
    nr_largura: 0,
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0,
    fl_visivelnomenu: ''
  },

  fMenuNotChanged: {
    id_opcao: 0,
    nm_texto: '',
    nm_link: '',
    id_opcaopai: 0,
    nr_ordem: 0,
    nm_classe: '',
    nr_largura: 0,
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0,
    fl_visivelnomenu: ''
  },

  fMenuClean: {
    id_opcao: 0,
    nm_texto: '',
    nm_link: '',
    id_opcaopai: 0,
    nr_ordem: 0,
    nm_classe: '',
    nr_largura: 0,
    dthr_reg: '1899-12-30T00:00:00.000Z',
    id_usuariosistema: 0,
    fl_visivelnomenu: ''
  },

  fIdOpcaoPai: cNivelZeroMenu,

  fNmTextoPai: '',

  fTempMenuList: []
}

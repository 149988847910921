import axios from 'axios'
import vm from '@/main'
import {
  cBaseURL,
  cBaseURLExt,
  cBaseURLCadastroOnline,
  cBaseURLDeclaracaoAssociado
} from '@/services/consts'

const api = axios.create({
  baseURL: cBaseURL,

  headers: {
    'Content-Type': 'application/json'
  }
})

const apiExt = axios.create({
  baseURL: cBaseURLExt,

  headers: {
    'Content-Type': 'application/json'
  }
})

const apiCadastroOnline = axios.create({
  baseURL: cBaseURLCadastroOnline,

  headers: {
    'Content-Type': 'application/json'
  }
})

const apiDeclaracao = axios.create({
  baseURL: cBaseURLDeclaracaoAssociado,

  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.response.use(
  lRes => {
    if (lRes.headers['x-authorization'] != undefined) {
      setToken(lRes.headers['x-authorization'])
    }
    return lRes
  },
  lErr => {
    if (lErr.response.status === 401) {
      vm.$router.push('/login')
    }
    throw lErr
  }
)

const setToken = aToken => {
  api.defaults.headers.common.Authorization = 'Bearer ' + aToken
}

const decodeToken = aToken => {
  return JSON.parse(atob(aToken.split('.')[1]))
}

export default api
export { api, apiExt, setToken, decodeToken, apiCadastroOnline, apiDeclaracao }

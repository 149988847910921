import usuarioState from './usuarioState'
import usuarioMutations from './usuarioMutations'
import usuarioGetters from './usuarioGetters'
import usuarioActions from './usuarioActions'

export default {
  state: usuarioState,
  mutations: usuarioMutations,
  getters: usuarioGetters,
  actions: usuarioActions
}

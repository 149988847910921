//import api from '../../../services/api'
import { cShowConsolelog, cOriginAllRecords } from '@/services/consts'

const _getMainMenuApp = aThis => {
  aThis.$store
    .dispatch('getMainMenuApp')
    .then(lSuccess => {
      if (!lSuccess) {
        _Logout(aThis)
      }
    })
    .catch(lError => {
      _ShowErrorMessage(lError.response.data.ErrorMessage)
      _Logout(aThis)
    })
}

const _jsonCopy = aSource => {
  return JSON.parse(JSON.stringify(aSource))
}

const _ConsoleLog = aMessage => {
  if (cShowConsolelog) {
    console.log(aMessage)
  }
}

const _onDeleteRecord = aThis => {
  if (aThis.fDataChanged) {
    aThis.ShowAlertMessage('Dados não gravados ainda...')
  } else {
    aThis.ShowDialogConfirm(
      'Alerta de Confirmação',
      'Confirma a exclusão do registro?',
      aThis.fModeDialogDelete
    )
  }
}

const _onDataChanged = aThis => {
  aThis.fDataChanged = true
}
/*
const _UpdateRecord = (aThis, aActionStoreUpdate, aRecord) => {
  if (!_VerifyTokenExpired(aThis)) {
    aThis.$store
      .dispatch(aActionStoreUpdate, aRecord)
      .then((lUpdateSuccess) => {
        aThis.fDataChanged = false;
        aThis.ShowMessageSnackBar("Registro Alterado...");
        aThis.onClearList();
      })
      .catch((lError) => {
        aThis.ShowErrorMessage(lError.response.data.ErrorMessage);
      });
  }
};

const _InsertRecord = (aThis, aActionStoreInsert, aRecord) => {
  if (!_VerifyTokenExpired(aThis)) {
    aThis.$store
      .dispatch(aActionStoreInsert, aRecord)
      .then((lUpdateSuccess) => {
        aThis.onClearList();
        aThis.ShowMessageSnackBar("Registro Inserido...");
        aThis.fDataChanged = false;
        aThis.fInInsert = false;
        aThis.fInEdit = true;
      })
      .catch((lError) => {
        aThis.ShowErrorMessage(lError.response.data.ErrorMessage);
      });
  }
};
*/
const _onToggleShowDialogSearch = aThis => {
  aThis.fShowDialogSearch = !aThis.fShowDialogSearch
}
/*
const _onGoDetailRecord = (aThis, aActionStoreGetRecord, aId) => {
  if (!_VerifyTokenExpired(aThis)) {
    aThis.$store
      .dispatch(aActionStoreGetRecord, aId)
      .then((lUpdateSuccess) => {
        aThis.fInEdit = true;
        aThis.ShowDialogData();
      })
      .catch((lError) => {
        aThis.ShowErrorMessage(lError.response.data.ErrorMessage);
        _onClearList();
      });
  }
};
*/
const _onCancelRecordChanged = (aThis, aActionStoreRollback) => {
  aThis.$store.dispatch(aActionStoreRollback)
  aThis.fDataChanged = false
  if (aThis.fInInsert) {
    _CancelAndBackToListData(aThis)
    // BAPJr - aqui deveria chamar a rotina para fechar a tela de DialogEdit
  }
}
/*
const _onGoSearchRecords = (aThis, aActionStoreGetRecord, aFieldsSearch) => {
  var lKey, lQueryString;

  lQueryString = "";

  for (lKey in aFieldsSearch) {
    if (aFieldsSearch.hasOwnProperty(lKey)) {
      if (lQueryString == "") {
        lQueryString = "?" + lQueryString + lKey + "=" + aFieldsSearch[lKey];
      } else {
        lQueryString = "&" + lQueryString + lKey + "=" + aFieldsSearch[lKey];
      }
    }
  }

  if (!_VerifyTokenExpired(aThis)) {
    aThis.$store
      .dispatch(aActionStoreGetRecord, lQueryString)
      .then((lResponse) => {
        aThis.fOriginOfRecords = cOriginPanelSearch;
        aThis.onToggleShowDialogSearch();
      })
      .catch((lError) => {
        aThis.ShowErrorMessage(lError.response.data.ErrorMessage);
        aThis.onClearList();
      });
  }
};
*/
const _onDoCancelAndBackToListData = aThis => {
  aThis.onCloseDialogConfirm()
  aThis.CancelAndBackToListData()
}

const _Logout = aThis => {
  aThis.$router.push('/logout')
}

const _onSaveRecord = aThis => {
  if (aThis.fInInsert) {
    aThis.InsertRecord(aThis.fRecord)
  } else if (aThis.fInEdit) {
    aThis.UpdateRecord(aThis.fRecord)
  }
}

const _onCloseDialogConfirm = aThis => {
  aThis.fShowDialogConfirm = false
}

const _onCancelAndBackToListData = aThis => {
  if (aThis.fDataChanged) {
    aThis.ShowDialogConfirm(
      'Alerta de Confirmação',
      'Registro alterado, confirma o abandono dos dados alterados?',
      aThis.fModeDialogCancelAndBack
    )
  } else {
    aThis.CancelAndBackToListData()
  }
}

const _onNewData = aThis => {
  aThis.clearRecord()
  aThis.ShowDialogData()
  aThis.fInInsert = true
  aThis.fInEdit = false
}

const _doMountedView = aThis => {
  aThis.onClearList(aThis)
}

const _onClearList = aThis => {
  aThis.clearListOfRecords()
  aThis.fOriginOfRecords = cOriginAllRecords
}

const _CancelAndBackToListData = aThis => {
  aThis.fInInsert = false
  aThis.fInEdit = false
  aThis.fDataChanged = false
  aThis.CloseDialogData(aThis)
}

const _CloseDialogData = aThis => {
  aThis.fDataChanged = false
  aThis.fShowDialogData = false
  aThis.fShowDialogSearch = false
}

const _ShowDialogConfirm = (aThis, aTitle, aText, aDialogMode) => {
  aThis.fModeDialogConfirm = aDialogMode
  aThis.fTextTitleDialog = aTitle
  aThis.fTextDialog = aText
  aThis.fShowDialogConfirm = true
}

const _ShowDialogData = aThis => {
  aThis.fDataChanged = false
  aThis.fShowDialogData = true
}

const _doRevalidateToken = aThis => {
  let lResult = false
  aThis.$store
    .dispatch('doRevalidateToken')
    .then(lTokenIsRevalidate => {
      lResult = lTokenIsRevalidate
    })
    .catch(lError => {
      _ShowErrorMessage(lError.response.data.ErrorMessage)
      lResult = false
    })
  return lResult
}

const _ShowMessageSnackBar = (aThis, aText) => {
  aThis.fTextSnackBar = aText
  aThis.fClassSnackBar = 'white info--text'
  aThis.fShowSnackBar = true
}

const _ShowAlertMessage = (aThis, aAlertMessage) => {
  if (aAlertMessage == undefined) {
    aAlertMessage = 'Erro ao atualizar registro...'
  }
  aThis.fTextSnackBar = aAlertMessage
  aThis.fClassSnackBar = 'warning white--text'
  aThis.fShowSnackBar = true
}

const _ShowErrorMessage = (aThis, aErrorMessage) => {
  if (aErrorMessage == undefined) {
    aErrorMessage = 'Erro ao atualizar registro...'
  }
  aThis.fTextSnackBar = aErrorMessage
  aThis.fClassSnackBar = 'error white--text'
  aThis.fShowSnackBar = true
}
/*
const _FormatDateTime = aDateParam => {
  let aDate_ob = aDateParam

  // adjust 0 before single digit date
  let aDate = ('0' + aDate_ob.getDate()).slice(-2)

  // current month
  let aMonth = ('0' + (aDate_ob.getMonth() + 1)).slice(-2)

  // current year
  let aYear = aDate_ob.getFullYear()

  // current hours
  let aHours = aDate_ob.getHours()

  // current minutes
  let aMinutes = aDate_ob.getMinutes()

  // current seconds
  let aSeconds = aDate_ob.getSeconds()

  // prints date & time in YYYY-MM-DD HH:MM:SS format
  let aDateStr =
    aDate +
    '/' +
    aMonth +
    '/' +
    aYear +
    ' ' +
    aHours +
    ':' +
    aMinutes +
    ':' +
    aSeconds

  return aDateStr
}
*/
export {
  _ShowDialogData,
  _ShowErrorMessage,
  _ShowAlertMessage,
  _ShowMessageSnackBar,
  _doRevalidateToken,
  _ShowDialogConfirm,
  _CloseDialogData,
  _CancelAndBackToListData,
  _onClearList,
  _doMountedView,
  _onNewData,
  _onCancelAndBackToListData,
  _onCloseDialogConfirm,
  _onSaveRecord,
  //_VerifyToken,
  _Logout,
  _onDoCancelAndBackToListData,
  _onToggleShowDialogSearch,
  _onDataChanged,
  _onDeleteRecord,
  //_OriginOfRecords,
  _ConsoleLog,
  _onCancelRecordChanged,
  _jsonCopy,
  _getMainMenuApp
  //_FormatDateTime
}

<template>
  <v-card class="elevation-12" width="100%" height="100%">
    <!-- TOOLBAR -->
    <TitleToolBar
      :fTitleForm="fTitleForm"
      :fHaveDialogSearch="fHaveDialogSearch"
      :fShowBtnBack="fShowBtnBack"
      :fShowButtons="fShowButtons"
      @onNewDataOwner="onNewData"
      @onClearListOwner="onClearList"
      @onToggleShowDialogSearchOwner="onToggleShowDialogSearch"
      @onEventBackOwner="onEventBack"
    />

    <!-- DialogSearch
      v-if="fShowDialogSearch"
      :fFieldsSearch="fFieldsSearch"
      :fShowDialogSearch="fShowDialogSearch"
      fTitleName="Informe o Tipo de Usu�rio a Pesquisar"
      @onToggleShowDialogSearchOwner="onToggleShowDialogSearch"
      @onGoSearchRecordsOwner="onGoSearchRecords"
    / -->

    <!-- LIST DATA -->
    <ListData
      :fFilter="fFilter"
      :fHeaders="fHeaders"
      :fListOfRecords="fListOfRecords"
      :fOriginOfRecords="fOriginOfRecords"
      @onGoDetailRecordOwner="onGoDetailRecord"
    />

    <!-- ShowMessage
      v-model="fShowSnackBar"
      :fShowSnackBar="fShowSnackBar"
      :fTimeout="5000"
      :fClassSnackBar="fClassSnackBar"
      :fTextSnackBar="fTextSnackBar"
    / -->
    <v-snackbar
      v-model="fShowSnackBar"
      :timeout="5000"
      :color="fClassSnackBar"
      >{{ fTextSnackBar }}</v-snackbar
    >

    <DialogConfirm
      v-if="fShowDialogConfirm"
      :fShowDialogConfirm="fShowDialogConfirm"
      :fModeDialogConfirm="fModeDialogConfirm"
      :fTextTitleDialog="fTextTitleDialog"
      :fTextDialog="fTextDialog"
      @onDoDeleteRecordOwner="onDoDeleteRecord"
      @onDoCancelAndBackToListDataOwner="onDoCancelAndBackToListData"
      @onCloseDialogConfirmOwner="onCloseDialogConfirm"
    />

    <!-- FORM DIALOG -->
    <CategoriaProfissionalDialogData
      v-model="fShowDialogData"
      :fShowDialogData="fShowDialogData"
      :fRecord="fRecord"
      :fInEdit="fInEdit"
      :fInInsert="fInInsert"
      :fDataChanged="fPropsDataChanged"
      @onDataChangedOwner="onDataChanged"
      @onSaveRecordOwner="onSaveRecord"
      @onDeleteRecordOwner="onDeleteRecord"
      @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
      @onGoItemCategoriaProfissionalOwner="onGoItemCategoriaProfissional"
      @onNewDataOwner="onNewData"
      @onCancelRecordChangedOwner="onCancelRecordChanged"
    />
  </v-card>
</template>

<script>
import CategoriaProfissionalDialogData from './CategoriaProfissionalDialogData.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import TitleToolBar from '@/components/TitleToolBar.vue'
//import DialogSearch from '@/components/DialogSearch.vue'
import ListData from '@/components/ListData.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  _onGoDetailRecord,
  _onGoSearchRecords,
  _onDoDeleteRecord,
  _InsertRecord,
  _UpdateRecord
} from '@/services/functions/crud'
import {
  _ShowDialogData,
  _ShowErrorMessage,
  _ShowAlertMessage,
  _ShowMessageSnackBar,
  //_doRevalidateToken,
  _ShowDialogConfirm,
  _CloseDialogData,
  _CancelAndBackToListData,
  _onClearList,
  _doMountedView,
  _onNewData,
  _onCancelAndBackToListData,
  _onCloseDialogConfirm,
  _onSaveRecord,
  //_VerifyTokenExpired,
  _Logout,
  _onDoCancelAndBackToListData,
  _onToggleShowDialogSearch,
  _onDataChanged,
  _onDeleteRecord,
  //_OriginOfRecords,
  //_ConsoleLog,
  _onCancelRecordChanged
  //cOriginPanelSearch
} from '@/services/functions/view'
import { cOriginAllRecords } from '@/services/consts'

export default {
  name: 'CategoriaProfissionalListData',

  components: {
    DialogConfirm,
    TitleToolBar,
    //DialogSearch,
    ListData,
    CategoriaProfissionalDialogData
  },

  data() {
    return {
      fShowButtons: true,

      fShowDialogSearch: false,
      fHaveDialogSearch: false,

      fTextTitleDialog: String,
      fTextDialog: String,

      fTextSnackBar: '',
      fShowSnackBar: false,

      fInInsert: false,
      fInEdit: false,

      fDataChanged: false,

      fShowDialogConfirm: false,

      fModeDialogConfirm: 0,
      fModeDialogDelete: 1,
      fModeDialogCancelAndBack: 2,

      fShowDialogData: false,
      fClassSnackBar: 'info--text',

      fOriginOfRecords: cOriginAllRecords,
      //fFieldsSearch: { NM_TEXTO: '' },

      fFilter: ''
    }
  },

  computed: {
    ...mapGetters({
      fListOfRecords: 'CategoriaProfissionalList',
      fRecord: 'CategoriaProfissional',
      fRecordNotChanged: 'CategoriaProfissionalNotChanged',
      fIdUserLogged: 'IdUserLogged',
      fHeaders: 'CategoriaProfissionalHeaders'
    }),

    fShowBtnBack: function() {
      return false
    },

    fTitleForm: function() {
      return 'Categoria Profissional'
    },

    fPropsDataChanged: function() {
      return this.fDataChanged
    }
  },

  mounted() {
    _doMountedView(this)
  },

  methods: {
    // -- MAPEAMENTOS DAS ACTIONS DO VUEX --
    ...mapActions({
      clearListOfRecords: 'clearCategoriaProfissionalList',
      clearRecord: 'clearCategoriaProfissional'
    }),

    // -- EVENTOS --

    onClearList() {
      _onClearList(this)
    },

    onNewData() {
      _onNewData(this)
    },

    onCancelRecordChanged() {
      _onCancelRecordChanged(this, 'rollBackCategoriaProfissional')
    },

    onCancelAndBackToListData() {
      _onCancelAndBackToListData(this)
    },

    onDoCancelAndBackToListData() {
      _onDoCancelAndBackToListData(this)
    },

    onSaveRecord() {
      _onSaveRecord(this)
    },

    onGoDetailRecord(aRecord) {
      _onGoDetailRecord(this, 'getCategoriaProfissionalByID', aRecord)
    },

    onGoSearchRecords(aFieldSearch) {
      this.fFieldsSearch.name = aFieldSearch
      _onGoSearchRecords(this, 'searchCadastroByName', this.fFieldsSearch)
    },

    onDataChanged() {
      _onDataChanged(this)
    },

    onDeleteRecord() {
      _onDeleteRecord(this)
    },

    onCloseDialogConfirm() {
      _onCloseDialogConfirm(this)
    },

    onDoDeleteRecord() {
      _onDoDeleteRecord(this, 'deleteCategoriaProfissional', this.fRecord)
    },

    onToggleShowDialogSearch() {
      _onToggleShowDialogSearch(this)
    },

    onGoItemCategoriaProfissional() {
      this.$store.dispatch('setIdOpcaoPaiRecordCurrent')
      this.onCancelAndBackToListData()
      this.onClearList()
    },

    onEventBack() {
      this.$store.dispatch('setIdOpcaoPaiBackRecord')
      this.onClearList()
      this.onGoDetailRecord(this.fRecord)
    },

    // -- METODOS / FUNCOES --

    Logout() {
      _Logout(this)
    },

    ShowErrorMessage(aErrorMessage) {
      _ShowErrorMessage(this, aErrorMessage)
    },

    ShowAlertMessage(aAlertMessage) {
      _ShowAlertMessage(this, aAlertMessage)
    },

    SetDefaultValues(aRecordParam) {
      aRecordParam.id_usuariosistema = this.fIdUserLogged
      aRecordParam.dthr_reg = new Date().toISOString()
      return aRecordParam
    },

    InsertRecord(aRecord) {
      aRecord = this.SetDefaultValues(aRecord)
      _InsertRecord(this, 'insertCategoriaProfissional', aRecord)
    },

    UpdateRecord(aRecord) {
      aRecord = this.SetDefaultValues(aRecord)
      _UpdateRecord(this, 'updateCategoriaProfissional', aRecord)
    },

    ShowDialogConfirm(aTitle, aText, aDialogMode) {
      _ShowDialogConfirm(this, aTitle, aText, aDialogMode)
    },

    ShowDialogData() {
      _ShowDialogData(this)
    },

    CloseDialogData() {
      _CloseDialogData(this)
    },

    CancelAndBackToListData() {
      _CancelAndBackToListData(this)
    },

    ShowMessageSnackBar(aText, aTypeAlert) {
      _ShowMessageSnackBar(this, aText, aTypeAlert)
    }
  }
}
</script>

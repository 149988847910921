<template>
  <div>
    <v-card-actions id="frmToolbar" class="info">
      <v-card-title class="white--text">
        {{ fTitleForm }}
      </v-card-title>

      <v-spacer />

      <v-btn
        small
        class="blue lighten-5 info--text"
        @click="onNewData"
        v-if="fShowButtonInsert"
      >
        <v-icon>mdi-plus</v-icon>Novo
      </v-btn>

      <v-btn
        v-if="fHaveDialogSearch"
        small
        class="blue lighten-5 info--text"
        @click="onToggleShowDialogSearch"
      >
        <v-icon>mdi-magnify</v-icon>Pesquisar
      </v-btn>

      <v-btn
        small
        class="blue lighten-5 info--text"
        @click="onClearList"
        v-if="fShowButtonRefresh"
      >
        <v-icon>mdi-refresh</v-icon>Atualizar
      </v-btn>

      <v-btn
        color="blue lighten-5 info--text"
        small
        v-if="fShowBtnBack"
        @click="onEventBack"
      >
        <v-icon>mdi-backup-restore</v-icon>Voltar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'TitleToolBar',

  props: {
    fTitleForm: String,
    fHaveDialogSearch: {
      type: Boolean,
      default: false
    },
    fShowBtnBack: {
      type: Boolean,
      default: false
    },
    fShowButtonInsert: {
      type: Boolean,
      default: true
    },
    fShowButtonRefresh: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onClearList() {
      this.$emit('onClearListOwner')
    },

    onToggleShowDialogSearch() {
      this.$emit('onToggleShowDialogSearchOwner')
    },

    onEventBack() {
      this.$emit('onEventBackOwner')
    }
  }
}
</script>

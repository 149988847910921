export default {
  SET_MSGERROLIST: (aState, aValue) => {
    aState.fMsgErroList = [...aValue]
  },

  SET_MSGERRO: (aState, aValue) => {
    aState.fMsgErro = { ...aValue }
    aState.fMsgErroNotChanged = { ...aValue }
  },

  CLEAR_MSGERRO: aState => {
    aState.fMsgErro = { ...aState.fMsgErroClean }
  }
}

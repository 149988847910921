//import { _ConsoleLog } from '@/services/functions/view'
export default {
  DadosDashBoard: aState => {
    return aState.fDadosDashBoard
  },
  // CHART lINE
  LabelsLine: aState => {
    return aState.fLabelsLine
  },
  SeriesLineAssociados: aState => {
    return aState.fSeriesLineAssociados
  },
  SeriesLineAssociadosEfetivos: aState => {
    return aState.fSeriesLineAssociadosEfetivos
  },
  SeriesLineAssociadosVinculados: aState => {
    return aState.fSeriesLineAssociadosVinculados
  },
  TitleChartLine: aState => {
    return aState.fTitleChartLine
  },
  // CHART PIE
  LabelsPieUF: aState => {
    return aState.fLabelsPieUF
  },
  SeriesPieAssociadosPorUF: aState => {
    return aState.fSeriesPieAssociadosPorUF
  },
  SeriesPieAssociadosEfetivosPorUF: aState => {
    return aState.fSeriesPieAssociadosEfetivosPorUF
  },
  SeriesPieAssociadosVinculadosPorUF: aState => {
    return aState.fSeriesPieAssociadosVinculadosPorUF
  },
  TitleChartPiePorUF: aState => {
    return aState.fTitleChartPiePorUF
  },
  // CHART BAR
  LabelsBarUF: aState => {
    return aState.fLabelsBarUF
  },
  SeriesBarAssociadosPorUF: aState => {
    return aState.fSeriesBarAssociadosPorUF
  },
  SeriesBarAssociadosEfetivosPorUF: aState => {
    return aState.fSeriesBarAssociadosEfetivosPorUF
  },
  SeriesBarAssociadosVinculadosPorUF: aState => {
    return aState.fSeriesBarAssociadosVinculadosPorUF
  },
  TitleChartBarPorUF: aState => {
    return aState.fTitleChartBarPorUF
  }
}
